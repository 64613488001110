/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useContext, useEffect, useRef, useState} from 'react'
import {Formik, Form, FormikValues, Field} from 'formik'
import {ICompany, IFinancials, IFinancingAsk} from '../interfaces'
import {Stepper, StepperComponent} from '../../shared/components/stepper'
import 'flatpickr/dist/flatpickr.min.css'
import {countries} from '../../../../utils/countries'
import {CompanyFormValidationSchema} from '../constants/CompanyFormValidationSchema'
import {CompanyCreateFormSteps} from '../constants'
import {
  useAddNewCompanyMutation,
  useCheckNameAvailabilityMutation,
  useGetFinancingTypesQuery,
  useGetSectorsQuery,
} from '../CompaniesApiSlice'
import {INameIdPair} from '../../shared/interfaces'
import {Toolbar} from '../../../../_theme/layout/components/Toolbar'
import {useNavigate} from 'react-router'
import {ICreateDocument} from '../../documents'
import {useAddNewDocumentMutation} from '../../documents/DocumentsApiSlice'
import {useAuth} from 'react-oidc-context'
import {countryCodes} from '../../../../utils/countryCodes'
import {CountryCustomSelect} from '../../shared/components/CountryCustomSelect'
import NumberFormatter from '../../shared/components/NumberFormatter'
import {MultiSelect} from '../../shared/components/MultiSelect'
import {FetchBaseQueryError} from '@reduxjs/toolkit/query'

const inits: ICompany = {
  details: {
    name: '',
    summary: '',
    logo: '',
    country: '',
    region: '',
    district: '',
    slogan: '',
    code: '',
    companyEmail: '',
    companyTelephone: '',
  },
  operations: {
    dateOfIncorporation: '',
    sectors: [],
    productLine: '',
    subSector: '',
  },
  financials: {
    financingTypes: [],
    financingAsk: '0',
    financingAskCurrency: '1',
  },
  contactPerson: {
    contactPersonName: '',
    contactPersonEmail: '',
    contactPersonTelephone: '',
    contactPersonAddress: '',
  },
}

const CreateCompany: FC = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState<any>()
  const [companyNameStatus, setCompanyNameStatus] = useState<boolean | null>(null)
  const [initValues] = useState<ICompany>(inits)
  const [countryCode, setCountryCode] = useState<string>('+256')
  const [submitting, setSubmitting] = useState(false)
  const [addNewCompany, {isLoading}] = useAddNewCompanyMutation()
  const [checkCompanyName, {isLoading: isCheckingCompanyName}] = useCheckNameAvailabilityMutation()
  const [createDocument] = useAddNewDocumentMutation()
  const navigate = useNavigate()
  const auth = useAuth()
  const [fileObject, setFileObject] = useState<ICreateDocument | null>(null)
  const {data: sectorsState} = useGetSectorsQuery()

  const companySectorsList = Object.values(sectorsState?.entities || {}).filter(
    Boolean
  ) as INameIdPair[]

  const {data: financingTypesState} = useGetFinancingTypesQuery()

  const financingTypeSelection = Object.values(financingTypesState?.entities || {})

  const stepName: any = (step: number) => {
    const steps = ['details', 'operations', 'financials', 'contactPerson', 'reviewAndSubmit']
    return steps[step - 1]
  }

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)

    if (stepper.current) {
      const step = stepName(stepper.current.currentStepIndex)

      setCurrentSchema(CompanyFormValidationSchema.pick([step]))
    }
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    setSubmitting(false)

    const currentStepIndex = stepper.current.currentStepIndex

    if (currentStepIndex !== undefined && currentStepIndex > 0) {
      const step = stepName(currentStepIndex - 1)

      stepper.current.goPrev()

      setCurrentSchema(CompanyFormValidationSchema.pick([step]))
    }
  }
  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    const file = event.target?.files ? event.target.files[0] : null
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64String = reader.result as string
        const base64Content = base64String.split(',')[1]
        const fileObj = {
          fileName: file.name,
          fileType: file.type,
          fileContent: base64Content,
          dateUploaded: new Date().toISOString(),
          fileSize: file.size,
        }
        setFileObject(fileObj)
        setFieldValue('details.logo', file)
      }
      reader.readAsDataURL(file)
    } else {
      setFieldValue('details.logo', null)
    }
  }

  const submitStep = async (values: ICompany, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    const step = stepName(stepper.current.currentStepIndex + 1)

    setCurrentSchema(CompanyFormValidationSchema.pick([step]))

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      setSubmitting(true)

      let logoId = null
      const user = auth.user
      const financingAsk = {
        currency: Number(values.financials.financingAskCurrency) || 0,
        ask: parseFloat(values.financials?.financingAsk?.replace(/,/g, '') || '0'),
      }

      try {
        if (fileObject) {
          const documents = [fileObject]
          const payload = {files: documents}
          const response = await createDocument(payload).unwrap()
          logoId = response[0].id
          setFileObject(null)
        }
        const response = await addNewCompany({
          country: values.details.country,
          region: values.details.region,
          district: values.details.district,
          name: values.details.name,
          summary: values.details.summary,
          logo: logoId,
          dateOfIncorporation: values.operations.dateOfIncorporation
            ? new Date(Number(values.operations.dateOfIncorporation), 0, 1).toISOString()
            : null,
          onboardingStatus: '1,0,0,0,0,0',
          sectors: values.operations.sectors,
          productLine: values.operations.productLine,
          subSector: values.operations.subSector,
          financingAsk,
          financingTypes: values.financials.financingTypes,
          contactPersonName: values.contactPerson.contactPersonName,
          contactPersonEmail: values.contactPerson.contactPersonEmail,
          contactPersonTelephone: values.contactPerson.contactPersonTelephone
            ? `${countryCode}-${values.contactPerson.contactPersonTelephone}`
            : '',
          contactPersonAddress: values.contactPerson.contactPersonAddress,
          createdById: user?.profile?.sub,
          createdByName: `${user?.profile?.given_name} ${user?.profile?.family_name}`,
        }).unwrap()

        const companyId = response.id
        navigate(`/companies/${companyId}`)
      } catch (error) {
        const errorCode = (error as FetchBaseQueryError)?.status?.toString()
        navigate(`/error/${errorCode}`)
      }
    }
  }

  const formRef = useRef<any>()

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  const handleCheckName = async (name: string) => {
    try {
      await checkCompanyName({name}).unwrap()
      setCompanyNameStatus(true)
    } catch (error) {
      setCompanyNameStatus(false)
    }
  }

  return (
    <>
      <Toolbar page='Add a New Company'></Toolbar>
      <div className='container-fluid p-0'>
        <div className='card'>
          <div
            ref={stepperRef}
            className='row stepper stepper-pills stepper-row'
            id='kt_modal_create_financier_stepper'
          >
            <Formik
              innerRef={formRef}
              validationSchema={currentSchema}
              initialValues={initValues}
              onSubmit={submitStep}
            >
              {({values, touched, errors, setFieldValue, setFieldTouched, isValid, dirty}) => {
                return (
                  <Form className='form w-100' noValidate id='kt_modal_create_form'>
                    <div className='card-body d-flex flex-column px-lg-20 py-lg-15'>
                      <h2 className='f-s ps-xl-0 ps-lg-0 ps-xxl-0 ps-md-0'>Create a Company</h2>
                      <div className='stepper-nav d-flex justify-content-center align-items-center h-100px py-lg-20 px-md-0 px-xl-0 px-xxl-0'>
                        <Stepper items={CompanyCreateFormSteps} />
                      </div>

                      <div className='flex-column-fluid py-lg-8 px-lg-0 px-xl-0 px-md-0 px-xxl-0'>
                        {/* General Information */}
                        <div className='current ' data-kt-stepper-element='content'>
                          <div className='w-100'>
                            <div className='fv-row row mb-10 p-0'>
                              <div className='col-lg-12'>
                                <label className='d-flex align-items-center fs-4 fw-bold mb-2 f-s'>
                                  <span className='required'>Company Name</span>
                                </label>

                                <Field
                                  type='text'
                                  className='form-control form-control-lg py-lg-5'
                                  name='details.name'
                                  placeholder=''
                                  onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                                    handleCheckName(e.target.value)
                                  }
                                />

                                {isCheckingCompanyName && (
                                  <div className='text-warning mt-2'>
                                    Checking name availability...
                                  </div>
                                )}
                                {companyNameStatus === false && (
                                  <div className='mt-2 text-danger'>Company already exists</div>
                                )}
                                {touched?.details?.name && (
                                  <div className='text-danger mt-2'>{errors?.details?.name}</div>
                                )}
                              </div>
                            </div>
                            <div className='fv-row row mb-10 p-0'>
                              <div className='col-lg-12'>
                                {/*About the company */}
                                <label className='d-flex align-items-center fs-4 f-s fw-bold mb-2'>
                                  <span className='required'>About Company</span>
                                </label>

                                <Field
                                  as='textarea'
                                  className='form-control form-control-lg py-lg-5'
                                  name='details.summary'
                                  placeholder=''
                                />
                                {touched?.details?.summary && (
                                  <div className='text-danger mt-2'>{errors?.details?.summary}</div>
                                )}
                              </div>
                            </div>
                            <div className=' fv-row row mb-10 p-0'>
                              <div className='col-lg-6'>
                                <label className='d-flex align-items-center fs-4 f-s fw-bold mb-2'>
                                  <span className='required'>Country</span>
                                </label>
                                <Field
                                  as='select'
                                  name='details.country'
                                  className='form-select select2-hidden-accessible py-lg-5 '
                                >
                                  <option value='' disabled selected>
                                    Select Country
                                  </option>
                                  {countries.map((option) => (
                                    <option key={option.value} value={option.label}>
                                      {option.label}
                                    </option>
                                  ))}
                                </Field>

                                {touched?.details?.country && (
                                  <div className='text-danger mt-2'>{errors?.details?.country}</div>
                                )}
                              </div>
                              <div className='col-lg-6'>
                                <label className='d-flex align-items-center fs-4 f-s fw-bold mb-2'>
                                  <span className='required'>Region</span>
                                </label>

                                <Field
                                  type='text'
                                  className='form-control form-control-lg py-lg-5 custom-placeholder'
                                  name='details.region'
                                  placeholder='Central'
                                />

                                {touched?.details?.region && (
                                  <div className='text-danger mt-2'>{errors?.details?.region}</div>
                                )}
                              </div>
                            </div>
                            <div className='fv-row row mb-10 p-0'>
                              <div className='col-lg-6'>
                                <label className='d-flex align-items-center fs-4 f-s fw-bold mb-2'>
                                  <span className='required'>District</span>
                                </label>
                                <Field
                                  type='text'
                                  className='form-control form-control-lg py-lg-5 custom-placeholder'
                                  name='details.district'
                                  placeholder='Kampala'
                                />
                                {touched?.details?.district && (
                                  <div className='text-danger mt-2'>
                                    {errors?.details?.district}
                                  </div>
                                )}
                              </div>
                              <div className='col-lg-6'>
                                <label className='d-flex align-items-center fs-4 f-s fw-bold mb-2'>
                                  <span>Company Logo</span>
                                </label>

                                <input
                                  type='file'
                                  className='form-control form-control-lg py-lg-5'
                                  name='generalInformation.logo'
                                  placeholder=''
                                  style={{width: '100%'}}
                                  onChange={(event) => handleFileChange(event, setFieldValue)}
                                  accept='image/*'
                                />
                                {touched?.details?.logo && (
                                  <div className='text-danger mt-2'>{errors?.details?.logo}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/*Business Operations */}
                        <div data-kt-stepper-element='content'>
                          <div className='w-100'>
                            {/*Region */}
                            <div className='fv-row row mb-10 p-0'>
                              <div className='col-lg-6'>
                                <label className=' fs-4 f-s fw-bold mb-2'>
                                  Year of Incorporation
                                </label>
                                <Field
                                  type='number'
                                  className='form-control form-control-lg py-lg-5'
                                  name='operations.dateOfIncorporation'
                                  placeholder=''
                                />
                                {touched?.operations?.dateOfIncorporation && (
                                  <div className='text-danger mt-2'>
                                    {errors?.operations?.dateOfIncorporation}
                                  </div>
                                )}
                              </div>
                              <div className='col-lg-6'>
                                <label className='d-flex align-items-center fs-4 f-s fw-bold mb-2'>
                                  <span>Sector(s)</span>
                                </label>
                                <MultiSelect
                                  selectedValues={values.operations.sectors}
                                  options={companySectorsList}
                                  setOption={(selectedOptions) =>
                                    setFieldValue('operations.sectors', selectedOptions)
                                  }
                                  size='lg'
                                />
                                {touched?.operations?.sectors && (
                                  <div className='text-danger mt-2'>
                                    {errors?.operations?.sectors}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className='fv-row row mb-10 p-0'>
                              <div className='col-lg-6'>
                                <label className='d-flex align-items-center fs-4 f-s fw-bold mb-2'>
                                  <span>Sub-sector</span>
                                </label>

                                <Field
                                  type='text'
                                  className='form-control form-control-lg py-lg-5 custom-placeholder'
                                  name='operations.subSector'
                                  placeholder='Refinery'
                                />
                                {touched?.operations?.subSector && (
                                  <div className='text-danger mt-2'>
                                    {errors?.operations?.subSector}
                                  </div>
                                )}
                              </div>
                              <div className='col-lg-6'>
                                <label className='d-flex align-items-center fs-4 f-s fw-bold mb-2'>
                                  <span>Product Line</span>
                                </label>

                                <Field
                                  type='text'
                                  className='form-control form-control-lg py-lg-5'
                                  name='operations.productLine'
                                  placeholder=''
                                />
                                {touched?.operations?.productLine && (
                                  <div className='text-danger mt-2'>
                                    {errors?.operations?.productLine}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Financial Information */}
                        <div data-kt-stepper-element='content'>
                          <div className='w-100'>
                            <div className='fv-row row mb-4 p-0'>
                              <div className='col-lg-6'>
                                <label className='d-flex align-items-center fs-4 f-s fw-bold mb-6'>
                                  <span>Select Financing Types</span>
                                </label>

                                {financingTypeSelection.map((option) => (
                                  <label
                                    className='d-flex flex-stack cursor-pointer mb-5'
                                    key={option?.id}
                                  >
                                    <span className='d-flex align-items-center me-2'>
                                      <span className='form-check form-check-custom me-3'>
                                        <Field
                                          className='form-check-input h-30px w-30px custom-checkbox'
                                          type='checkbox'
                                          name='financials.financingTypes'
                                          value={option?.id}
                                          checked={
                                            (option &&
                                              values.financials?.financingTypes?.includes(
                                                option
                                              )) ||
                                            false
                                          }
                                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const isChecked = e.target.checked
                                            const newFinancingTypes = isChecked
                                              ? [
                                                  ...(values.financials.financingTypes || []),
                                                  option,
                                                ]
                                              : (values.financials.financingTypes || []).filter(
                                                  (opt) => opt.id !== option?.id
                                                )
                                            setFieldValue(
                                              'financials.financingTypes',
                                              newFinancingTypes
                                            )
                                          }}
                                        />
                                      </span>
                                      <span className='d-flex flex-column'>
                                        <span className='fw-bolder fs-6'>{option?.name}</span>
                                      </span>
                                    </span>
                                  </label>
                                ))}
                              </div>
                            </div>
                            <div className='fv-row row mb-4 p-0'>
                              <div className='col-lg-6'>
                                <div className='fv-row '>
                                  <label className='d-flex align-items-center fs-4 f-s fw-bold mb-2'>
                                    <span>Investment Ask</span>
                                  </label>

                                  <div className='row'>
                                    <div className='col-3 pe-0 '>
                                      <Field
                                        as='select'
                                        name='financials.financingAskCurrency'
                                        className='form-select form-select-solid rounded-end-0 py-lg-5 ps-8'
                                        aria-label='Select financing currency for Ask'
                                        multiple={false}
                                      >
                                        <option value='1'>UGX</option>
                                        <option value='0'>USD</option>
                                        <option value='2'>EUR</option>
                                      </Field>
                                    </div>
                                    <div className='col-9 ps-0 '>
                                      <NumberFormatter
                                        name='financials.financingAsk'
                                        className='border-start-0 rounded-start-0 py-lg-5'
                                        setFieldValue={setFieldValue}
                                        initialValue={values.financials.financingAsk || 0}
                                      />
                                      {touched?.financials?.financingAsk && (
                                        <div className='text-danger mt-2'>
                                          {errors?.financials?.financingAsk}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* contact person */}
                        <div data-kt-stepper-element='content'>
                          <div className='w-100'>
                            <div className='fv-row row mb-10 p-0'>
                              <div className='col-lg-6'>
                                <label className='d-flex align-items-center fs-4 f-s fw-bold mb-2'>
                                  <span className='required'>Name of Contact Person</span>
                                </label>

                                <Field
                                  type='text'
                                  className='form-control form-control-lg py-lg-5'
                                  name='contactPerson.contactPersonName'
                                  placeholder=''
                                />
                                {touched?.contactPerson?.contactPersonName && (
                                  <div className='text-danger mt-2'>
                                    {errors?.contactPerson?.contactPersonName}
                                  </div>
                                )}
                              </div>
                              <div className='col-lg-6'>
                                <label className='d-flex align-items-center fs-4 f-s fw-bold mb-2'>
                                  <span>Email Address</span>
                                </label>

                                <Field
                                  type='text'
                                  className='form-control form-control-lg py-lg-5'
                                  name='contactPerson.contactPersonEmail'
                                  placeholder=''
                                />
                                {touched?.contactPerson?.contactPersonEmail && (
                                  <div className='text-danger mt-2'>
                                    {errors?.contactPerson?.contactPersonEmail}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className='fv-row row mb-10 p-0'>
                              <div className='col-lg-6'>
                                <label className='d-flex align-items-center fs-4 f-s fw-bold mb-2'>
                                  <span>Phone Number</span>
                                </label>

                                <div className='input-group mb-3 col-lg-6 '>
                                  <CountryCustomSelect
                                    name='dialCode'
                                    options={countryCodes}
                                    style={'py-lg-6'}
                                    setCountryCode={setCountryCode}
                                  />
                                  <Field
                                    type='text'
                                    className='form-control form-control-lg py-lg-5'
                                    name='contactPerson.contactPersonTelephone'
                                    placeholder=''
                                  />
                                </div>
                                {touched?.contactPerson?.contactPersonTelephone && (
                                  <div className='text-danger mt-2'>
                                    {errors?.contactPerson?.contactPersonTelephone}
                                  </div>
                                )}
                              </div>
                              <div className='col-lg-6'>
                                <label className='d-flex align-items-center fs-4 f-s fw-bold mb-2'>
                                  <span>Address Line</span>
                                </label>

                                <Field
                                  type='text'
                                  className='form-control form-control-lg py-lg-5'
                                  name='contactPerson.contactPersonAddress'
                                  placeholder=''
                                />
                                {touched?.contactPerson?.contactPersonAddress && (
                                  <div className='text-danger mt-2'>
                                    {errors?.contactPerson?.contactPersonAddress}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* submission */}
                        <div data-kt-stepper-element='content'>
                          <div className='w-100 text-center'></div>
                        </div>
                      </div>
                      <div className='row mb-10 pt-0 px-lg-0 px-xl-0 px-md-0 px-xxl-0'>
                        <div className='col-lg-6'>
                          <button
                            type='button'
                            data-kt-stepper-action='previous'
                            onClick={prevStep}
                            className='btn btn-lg btn-outline btn-outline-secondary'
                            style={{
                              color:
                                stepper?.current?.currentStepIndex &&
                                stepper.current.currentStepIndex > 0
                                  ? 'black'
                                  : 'gray',
                            }}
                          >
                            Back
                          </button>
                        </div>
                        <div className='col-lg-6 text-md-end'>
                          <button
                            type='submit'
                            className={`btn btn-lg ${
                              stepper?.current?.currentStepIndex ===
                              stepper?.current?.totatStepsNumber!
                                ? 'btn-danger'
                                : 'btn-outline btn-outline-secondary'
                            }`}
                            style={{
                              color:
                                stepper?.current?.currentStepIndex ===
                                stepper?.current?.totatStepsNumber!
                                  ? 'white'
                                  : isValid && dirty
                                  ? 'black'
                                  : 'gray',
                            }}
                            disabled={isCheckingCompanyName || companyNameStatus === false}
                          >
                            <span className='indicator-label'>
                              {!submitting && (
                                <>
                                  {stepper?.current?.currentStepIndex ===
                                  stepper?.current?.totatStepsNumber!
                                    ? 'Submit'
                                    : 'Next'}
                                </>
                              )}
                              {submitting && (
                                <>
                                  <span className='spinner-border h-15px w-15px align-middle text-white-400'></span>
                                </>
                              )}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </div>
      </div>
    </>
  )
}

export {CreateCompany}
