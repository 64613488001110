import {Link, useParams} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_theme/helpers'
import clsx from 'clsx'
import {useAuth} from 'react-oidc-context' // Import useAuth
import {FC, useEffect, useState} from 'react' // Import useState
import '../../../../styles/custom.css'
import {OptionsPopup} from '../../shared/components/OptionsPopup'
import {useDispatch} from 'react-redux'
import {setCompanyFilters} from '../../../../data/redux/settingsReducer'
import {useLocation} from 'react-router'

type MenuItem = {
  name: string
  image: string
}

type props = {
  isFinancierPage?: boolean
}

const FinancierPagesHeader: FC<props> = ({isFinancierPage = true}) => {
  const toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

  const auth = useAuth()
  const dispatch = useDispatch()
  const user = auth.user
  const {financierId} = useParams<{financierId: string}>() as {financierId: string}
  const location = useLocation()

  const getMenuNameFromPath = (path: string) => {
    const pathSegments = path.split('/')
    let staticWord = pathSegments[3]
    staticWord = staticWord ? `${staticWord.charAt(0).toUpperCase()}${staticWord.slice(1)}` : ''
    return staticWord
  }
  useEffect(() => {
    setSelectedItem(getMenuNameFromPath(location.pathname))
  }, [location.pathname])

  const menuItems: MenuItem[] = [
    // {name: 'Dashboard', image: 'dashboard-menu-item-image'},
    {name: 'Pipeline', image: 'pipeline-menu-item-image'},
    {name: 'Calls', image: 'calls-menu-item-image'},
    // {name: 'Deals', image: 'deals-menu-item-image'},
    // {name: 'Settings', image: 'settings-menu-item-image'},
  ]

  const [selectedItem, setSelectedItem] = useState(() => getMenuNameFromPath(location.pathname)) // State to track selected item
  async function handleLogout() {
    try {
      // await auth.signoutSilent()
      await auth.signoutRedirect()
    } catch (error) {
      console.error('Error occurred during login: ', error)
    }
  }

  const handleMenuItemClick = (item: MenuItem) => {
    setSelectedItem(item.name)
    if (item.name === 'Pipeline') {
      dispatch(
        setCompanyFilters({
          countries: [],
          startYearOfOperation: '',
          endYearOfOperation: '',
          sectors: [],
          financingTypes: [],
          startInvestmentAsk: '',
          endInvestmentAsk: '',
          investmentAskCurrency: '',
        }),
      )
    }
  }

  return (
    <section className="fixed-top">
      <div className="bg-black border-bottom d-flex align-items-center justify-content-between py-1 px-12">
        <Link to={`/financier/${financierId}/pipeline`} onClick={() => setSelectedItem('Pipeline')}>
          <img
            alt="Logo"
            className="h-25px logo"
            src={toAbsoluteUrl('/media/logos/logo-red-white.png')}
          />
        </Link>
        {isFinancierPage && (
          <>
            <div className="d-flex align-items-stretch flex-shrink-0">
              <div className={clsx('d-flex align-items-center')}>
                <div className={'d-flex align-items-center'}>
                  <div className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}>
                    <span className="position-relative">
                      <span className="fas fa-bell text-white" style={{fontSize: '24px'}}></span>

                      <span
                        className="position-absolute bottom-0 start-100 translate-middle badge rounded-pill bg-danger"
                        style={{fontSize: '0.8em', padding: '0.25em 0.5em'}}
                      >
                        3
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <OptionsPopup
                isFinancierHeader={true}
                handleLogout={handleLogout}
                includeFinancierHeaderOptions={true}
              />
            </div>
          </>
        )}
      </div>
      {isFinancierPage && (
        <div className="navbar navbar-expand-lg bg-white border-bottom px-6">
          <div className="d-flex justify-content-start align-items-center">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              {menuItems.map((item) => (
                <li className="financier-page nav-item " key={item.name}>
                  <Link
                    className={`nav-link fs-6 fw-medium text-dark py-1`}
                    to={`/financier/${financierId}/${item.name.toLowerCase()}`}
                  >
                    <button
                      className={`btn btn-sm ms-lg-3 d-flex align-items-center justify-content-center text-hover-danger btn-active-secondary h-35px br-40px ${
                        selectedItem === item.name ? 'bg-secondary text-danger' : 'bg-body'
                      }`}
                      onClick={() => handleMenuItemClick(item)}
                    >
                      <img
                        src={toAbsoluteUrl(`/media/images/${item.image}.svg`)}
                        alt={item.name}
                        className={`h-17px w-20px ${
                          selectedItem === item.name ? 'selected-financier-menu-tab' : ''
                        }`}
                      />
                      <span className="ms-3">{item.name}</span>
                    </button>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </section>
  )
}

export default FinancierPagesHeader
