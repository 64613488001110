/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import CardInitials from '../../shared/components/CardInitials'
import CardBadge from '../../shared/components/CardBadge'
import CardDetail from '../../shared/components/CardDetail'
import ProgressBar from '../../shared/components/ProgressBar'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../data/redux/Store'
import { createServiceProviderSelectors } from '../ServiceProvidersApiSlice'
import { getInitials, trimString } from '../../../../utils/stringHelpers'
import { timeAgo } from '../../../../utils/dateHelpers'
import { IListPageFilters } from '../../companies/interfaces'
import { useGetDocumentsQuery } from '../../documents/DocumentsApiSlice'
import CustomSkeleton from '../../shared/components/CustomSkeleton'
import IdenticonAvatar from '../../shared/components/IdenticonAvatar'

type ServiceProviderCardProps = {
  id: string
  page: number
  pageSize: number
  searchTerm: string
  filters: IListPageFilters
}

export const ServiceProviderCard: FC<ServiceProviderCardProps> = ({
  id,
  page,
  pageSize,
  searchTerm,
  filters,
}) => {
  const navigate = useNavigate()
  const { selectById: selectServiceProviderById } = createServiceProviderSelectors({
    page,
    pageSize,
    searchTerm,
    ...filters,
  })
  const serviceProvider = useSelector((state: RootState) => selectServiceProviderById(state, id))
  const serviceProviderInitials = getInitials(serviceProvider?.name,3)

  const { data: documentsState, isLoading: gettingLogo } = useGetDocumentsQuery(
    serviceProvider?.logo ? [serviceProvider.logo] : [],
    {
      skip: !serviceProvider?.logo,
    }
  )
  const logo = serviceProvider?.logo ? documentsState?.entities[serviceProvider.logo] : null

  const handleNavigate = () => {
    navigate(`/services/providers/${id}`, {
      state: { currentPage: page, pageSize, searchTerm, ...filters },
    })
  }

  return (
    <div className='col-md-6 col-lg-4 col-sm-12 min-height-200px pb-6'>
      <div
        onClick={handleNavigate}
        className='card border border-2 border-gray-300 mb-1 h-100 d-flex flex-column justify-content-between'
        style={{ cursor: 'pointer'}}
      >
        <div className='d-flex border-0 pt-3 px-5 justify-content-between align-items-center'>
          {gettingLogo ? (
            <CustomSkeleton height={50} width={75} className='rounded' />
          ) : logo ? (
            <img
              src={`data:${logo.fileType};base64,${logo.fileContent}`}
              alt={`${serviceProvider?.name} logo`}
              height={50}
              width={75}
              style={{ objectFit: 'contain' }}
            />
          ) : (
            <IdenticonAvatar identifier={serviceProviderInitials} size={50} />
          )}
          <div className='d-flex py-4'>
            <CardBadge
              text={(serviceProvider?.serviceProviderSectors?.[0]?.sectorName ?? '').toUpperCase()}
            />
          </div>
        </div>
        <div className='px-5 d-flex justify-content-between align-items-center h-50px'>
          <span className='fw-bolder text-dark fs-6 text-truncate' style={{ maxWidth: '250px' }}>
            {serviceProvider?.name ?? ''}
          </span>
        </div>
        {/* <Row className='gap-1 px-5'>
            <Col>
              
            </Col>
            <Col>
              
            </Col>
          </Row> */}

        <Row className={`px-5`}>
          <Col className='pe-2'>
            <CardDetail
              title='Years of Operation'
              value={
                serviceProvider?.yearOfIncorporation
                  ? timeAgo(serviceProvider?.yearOfIncorporation)
                  : '-'
              }
            />
          </Col>
          <Col className='ps-2'>
            <CardDetail
              title="Location"
              value={`${serviceProvider?.country}, ${serviceProvider?.district}`}
            />
          </Col>
        </Row>

        <Row className='px-5 mb-2'>
          <Col>
            <ProgressBar
              value={serviceProvider?.numberOfCompletedProjects ?? 0}
              total={serviceProvider?.numberOfProjects ?? 0}
              title='Completed Projects'
              isPercentage={false}
              bold={true}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}
