import {
  createSelector,
  createEntityAdapter,
  EntityState,
} from '@reduxjs/toolkit'
import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'
import {RootState} from '../../../data/redux/Store'
import {AUTH_TOKEN_KEY, env} from '../../../data/constants'
import {IDocument} from './IDocument'

const documentsAdapter = createEntityAdapter<IDocument>({
  selectId: (document) => document.id,
})

const documentsInitialState = documentsAdapter.getInitialState()

const baseQuery = fetchBaseQuery({
  baseUrl: env.Documents,
  validateStatus: (response, result) => {
    if (result && result.isError) return false
    if (response.status >= 200 && response.status <= 300) return true
    if (response.status >= 400 && response.status <= 500) return false
    return false
  },
  prepareHeaders: (headers, {getState}) => {
    const accessToken = localStorage.getItem(AUTH_TOKEN_KEY)
    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`)
    }
    return headers
  },
})
export const DocumentsApiSlice = createApi({
  reducerPath: 'documentsApi',
  baseQuery,
  tagTypes: ['Document'],
  endpoints: (builder) => ({
    getDocuments: builder.query<EntityState<IDocument>, string[]>({
      query: (ids) => {
        if (!ids || ids.length === 0) {
          return {url: '/documents', params: {}}
        }
        return {
          url: '/documents',
          params: {DocumentIds: ids},
        }
      },
      transformResponse: (response: IDocument[]) => {
        const entities = documentsAdapter.setAll(documentsInitialState, response)
        return {
          ...entities,
        }
      },
      providesTags: (result: EntityState<IDocument> | undefined) => {
        if (result?.ids) {
          return [
            {type: 'Document', id: 'LIST'},
            ...result.ids.map((id) => ({type: 'Document' as const, id})),
          ]
        } else return [{type: 'Document', id: 'LIST'}]
      },
    }),
    addNewDocument: builder.mutation({
      query: (documentData) => {
        return {
          url: '/documents/upload',
          method: 'POST',
          body: documentData,
        }
      },
      invalidatesTags: (_result, _error, arg) => [{type: 'Document', id: arg.id}],
    }),
    deleteDocument: builder.mutation({
      query: (id) => ({
        url: `/documents/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [{type: 'Document', id: arg.id}],
    }),
  }),
})

export const {
  useGetDocumentsQuery,
  useAddNewDocumentMutation,
  useDeleteDocumentMutation,
} = DocumentsApiSlice

export const selectDocumentsResult = (state: RootState, ids: string[]) =>
  DocumentsApiSlice.endpoints.getDocuments.select(ids)(state)

export const selectDocumentsData = (ids: string[]) =>
  createSelector(
    (state: RootState) => selectDocumentsResult(state, ids),
    (documentsResult) => documentsResult?.data ?? documentsInitialState,
  )

export const createDocumentsSelectors = (ids: string[]) => {
  const selectData = selectDocumentsData(ids)
  return documentsAdapter.getSelectors((state: RootState) => selectData(state))
}
  
  
  