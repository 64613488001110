import React, {useState} from 'react'
import {Formik, Form, Field} from 'formik'
import {Modal} from 'react-bootstrap'
import {countries} from '../../../../utils/countries'
import * as Yup from 'yup'
import {
  createServiceProviderSelectors,
  useGetServiceProviderContactsQuery,
  useGetServiceProvidersQuery,
  useUpdateServiceProviderContactMutation,
  useUpdateServiceProviderMutation,
} from '../ServiceProvidersApiSlice'
import {useParams} from 'react-router-dom'
import {KTSVG} from '../../../../_theme/helpers'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../data/redux/Store'
import {IServiceProviderContacts} from '../interfaces'
import {countryCodes} from '../../../../utils/countryCodes'
import {CountryCustomSelect} from '../../shared/components/CountryCustomSelect'

type Props = {
  showModal: boolean
  handleClose: () => void
  pageSize: number
  currentPage: number
}

export const EditContactInformation: React.FC<Props> = ({
  showModal,
  handleClose,
  pageSize,
  currentPage,
}) => {
  const ContactInformationValidationSchema = Yup.object().shape({
    country: Yup.string().required('Country is required'),
    district: Yup.string().required('District is required'),
    address: Yup.string().nullable(),
    facebook: Yup.string().nullable(),
    linkedIn: Yup.string().nullable(),
    twitter: Yup.string().nullable(),
    website: Yup.string().nullable(),
    serviceProviderContactPersonName: Yup.string().required('Contact person name is required'),
    serviceProviderContactPersonEmail: Yup.string().email().nullable(),
    serviceProviderContactPersonTelephone: Yup.string()
      .matches(/^[0-9]{9}$/, 'Phone number must be 9 digits')
      .nullable(),
    serviceProviderContactPersonAddress: Yup.string().nullable(),
  })

  const [updateServiceProvider, {isLoading: isUpdateServiceProviderLoading}] =
    useUpdateServiceProviderMutation()
  const [updateServiceProviderContact, {isLoading: isUpdateServiceProviderContactLoading}] =
    useUpdateServiceProviderContactMutation()
  const {id} = useParams<{id: string}>() as {id: string}
  useGetServiceProvidersQuery({page: currentPage, pageSize: pageSize})
  const {selectById: selectServiceProviderById} = createServiceProviderSelectors({
    page: currentPage,
    pageSize,
  })
  const serviceProviderDetails = useSelector((state: RootState) =>
    selectServiceProviderById(state, id)
  )
  const {data: serviceProviderContactsState} = useGetServiceProviderContactsQuery(id)

  let serviceProviderContact: IServiceProviderContacts | null | undefined = null
  if (serviceProviderContactsState?.entities) {
    const contactsArray = Object.values(serviceProviderContactsState?.entities)
    serviceProviderContact = contactsArray.length > 0 ? contactsArray[0] : null
  }
  const countryPrefix =
    serviceProviderContact?.serviceProviderContactPersonTelephone?.split('-')[0] || '+256'
  const phoneNumber =
    serviceProviderContact?.serviceProviderContactPersonTelephone?.split('-')[1] || ''
  const [countryCode, setCountryCode] = useState<string>(countryPrefix)

  const contactInformationForm = {
    ...serviceProviderDetails,
    ...serviceProviderContact,
    serviceProviderContactPersonTelephone: phoneNumber,
  }

  const handleSave = async (values: any) => {
    const updatedServiceProviderDetails = {
      country: values.country,
      region: values.region,
      district: values.district,
      address: values.address,
      website: values.website,
      facebook: values.facebook,
      linkedIn: values.linkedIn,
      twitter: values.twitter,
      id: serviceProviderDetails?.id,
    }

    const updatedContactPersonDetails = {
      id: serviceProviderContact?.id ?? null,
      name: values.serviceProviderContactPersonName,
      email: values.serviceProviderContactPersonEmail,
      telephone: values.serviceProviderContactPersonTelephone
        ? `${countryCode}-${values.serviceProviderContactPersonTelephone}`
        : '',
      address: values.serviceProviderContactPersonAddress,
      providerId: serviceProviderDetails?.id,
    }
    try {
      await Promise.all([
        updateServiceProvider(updatedServiceProviderDetails).unwrap(),
        updateServiceProviderContact(updatedContactPersonDetails).unwrap(),
      ])
      handleClose()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Modal
      id='kt_edit_contact_information'
      aria-hidden='true'
      dialogClassName='h-auto'
      show={showModal}
      onHide={handleClose}
    >
      <div className='modal-content w-700px' role='document'>
        <Formik
          initialValues={contactInformationForm || {}}
          validationSchema={ContactInformationValidationSchema}
          onSubmit={handleSave}
        >
          {({errors, touched, values, isValid}) => (
            <Form>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title ps-1' id='editFinancialsModalLabel'>
                    Edit Contact Information
                  </h5>
                  <div
                    className='btn btn-sm btn-icon btn-active-color-danger'
                    onClick={handleClose}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                  </div>
                </div>
                <div className='modal-body px-9'>
                  <div className='row fv mb-4'>
                    <div className='col-md-6 mb-3 mb-3'>
                      <label
                        htmlFor='country'
                        className='d-flex align-items-center fs-5 fw-bold mb-2 text-dark required'
                      >
                        Country
                      </label>
                      <Field
                        as='select'
                        name='country'
                        className='form-select form-select-sm select2-hidden-accessible'
                        style={{color: '#616162', fontWeight: 300}}
                      >
                        <option value='' disabled>
                          Select Country
                        </option>
                        {countries.map((option) => (
                          <option key={option.value} value={option.label}>
                            {option.label}
                          </option>
                        ))}
                      </Field>
                      {touched.country && <div className='text-danger mt-2'>{errors.country}</div>}
                    </div>
                    <div className='col-md-6 mb-3'>
                      <label htmlFor='district' className='form-label text-dark required'>
                        District
                      </label>
                      <Field
                        type='text'
                        name='district'
                        className='form-control form-control-sm'
                        style={{color: '#616162', fontWeight: 300}}
                      />
                      {touched.district && (
                        <div className='text-danger mt-2'>{errors.district}</div>
                      )}
                    </div>
                  </div>
                  <div className='row fv mb-4'>
                    <div className='col-md-6 mb-3'>
                      <label htmlFor='address' className='form-label text-dark'>
                        Address
                      </label>
                      <Field
                        type='text'
                        name='address'
                        className='form-control form-control-sm'
                        style={{color: '#616162', fontWeight: 300}}
                      />
                      {touched.address && <div className='text-danger mt-2'>{errors.address}</div>}
                    </div>
                    <div className='col-md-6 mb-3'>
                      <label htmlFor='facebook' className='form-label text-dark'>
                        Facebook
                      </label>
                      <Field
                        type='text'
                        name='facebook'
                        className='form-control form-control-sm'
                        style={{color: '#616162', fontWeight: 300}}
                      />
                      {touched.facebook && (
                        <div className='text-danger mt-2'>{errors.facebook}</div>
                      )}
                    </div>
                  </div>

                  {/* Socials */}
                  <div className='row fv mb-4'>
                    <div className='col-md-6 mb-3'>
                      <label htmlFor='linked' className='form-label text-dark'>
                        LinkedIn
                      </label>
                      <Field
                        type='text'
                        name='linkedIn'
                        className='form-control form-control-sm'
                        style={{color: '#616162', fontWeight: 300}}
                      />
                      {touched.linkedIn && (
                        <div className='text-danger mt-2'>{errors.linkedIn}</div>
                      )}
                    </div>
                    <div className='col-md-6 mb-3'>
                      <label htmlFor='twitter' className='form-label text-dark'>
                        Twitter
                      </label>
                      <Field
                        type='text'
                        name='twitter'
                        className='form-control form-control-sm'
                        style={{color: '#616162', fontWeight: 300}}
                      />
                      {touched.twitter && <div className='text-danger mt-2'>{errors.twitter}</div>}
                    </div>
                  </div>

                  <div className='row fv mb-4'>
                    <div className='col-md-6 mb-3'>
                      <label htmlFor='website' className='form-label text-dark'>
                        Website URL
                      </label>
                      <Field
                        type='text'
                        name='website'
                        className='form-control form-control-sm'
                        style={{color: '#616162', fontWeight: 300}}
                      />
                      {touched.website && <div className='text-danger mt-2'>{errors.website}</div>}
                    </div>
                    <div className='col-md-6 mb-3'>
                      <label
                        htmlFor='serviceProviderContactPersonName'
                        className='form-label text-dark required'
                      >
                        Name of Contact Person
                      </label>
                      <Field
                        type='text'
                        name='serviceProviderContactPersonName'
                        className='form-control form-control-sm'
                        style={{color: '#616162', fontWeight: 300}}
                      />
                      {touched.serviceProviderContactPersonName && (
                        <div className='text-danger mt-2'>
                          {errors.serviceProviderContactPersonName}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Contact Person Details */}

                  <div className='row fv mb-4'>
                    <div className='col-md-6 mb-3'>
                      <label
                        htmlFor='serviceProviderContactPersonEmail'
                        className='form-label text-dark'
                      >
                        Email of Contact person
                      </label>
                      <Field
                        type='text'
                        name='serviceProviderContactPersonEmail'
                        className='form-control form-control-sm'
                        style={{color: '#616162', fontWeight: 300}}
                      />
                      {touched.serviceProviderContactPersonEmail && (
                        <div className='text-danger mt-2'>
                          {errors.serviceProviderContactPersonEmail}
                        </div>
                      )}
                    </div>
                    <div className='col-md-6 mb-3'>
                      <label
                        htmlFor='serviceProviderContactPersonTelephone'
                        className='form-label text-dark'
                      >
                        Phone Number of Contact person
                      </label>
                      <div className='input-group input-group-sm mb-3'>
                        <CountryCustomSelect
                          name='dialCode'
                          options={countryCodes}
                          setCountryCode={setCountryCode}
                          countryCode={countryPrefix}
                        />
                        <Field
                          type='text'
                          className='form-control form-control-sm border-start-0 rounded-start-0'
                          style={{color: '#616162', fontWeight: 300}}
                          name='serviceProviderContactPersonTelephone'
                        />
                        {touched.serviceProviderContactPersonTelephone && (
                          <div className='text-danger mt-2'>
                            {errors.serviceProviderContactPersonTelephone}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='row fv mb-4'>
                    <div className='col-md-6 mb-3'>
                      <label
                        htmlFor='serviceProviderContactPersonAddress'
                        className='form-label text-dark'
                      >
                        Address line of Contact person
                      </label>
                      <Field
                        type='text'
                        name='serviceProviderContactPersonAddress'
                        style={{color: '#616162', fontWeight: 300}}
                        className='form-control form-control-sm'
                      />
                      {touched.serviceProviderContactPersonAddress && (
                        <div className='text-danger mt-2'>
                          {errors.serviceProviderContactPersonAddress}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='modal-footer '>
                  <button
                    type='button'
                    className='btn btn-secondary text-dark'
                    onClick={handleClose}
                  >
                    Close
                  </button>
                  <button
                    type='submit'
                    className='btn btn-danger'
                    onClick={() => {
                      handleSave(values)
                    }}
                    disabled={
                      !isValid ||
                      isUpdateServiceProviderLoading ||
                      isUpdateServiceProviderContactLoading
                    }
                  >
                    <span className='indicator-label'>
                      {!isUpdateServiceProviderLoading &&
                        !isUpdateServiceProviderContactLoading &&
                        'Save'}
                      {(isUpdateServiceProviderLoading ||
                        isUpdateServiceProviderContactLoading) && (
                        <>
                          <span className='spinner-border h-15px w-15px align-middle text-white-400'></span>
                        </>
                      )}
                    </span>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}