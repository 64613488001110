import CardBadge from '../../shared/components/CardBadge'
import {ICallDetails} from '../interfaces/ICallDetails'
import {Container, Row, Col} from 'react-bootstrap'

interface DetailSectionProps {
  title: string
  content: string | string[] | string[][]
  isBadge?: boolean
}

interface CallDescriptionProps {
  description: string
  qualifications: string[]
  postedOn: string
  fundAmount: string
  sectors: string[]
  locationScope: string[][]
  deadline: string
}

const DetailSection: React.FC<DetailSectionProps> = ({title, content, isBadge = false}) => (
  <div
    className="mb-3 p-3 border-bottom border-1 border-gray-200 w-100 d-flex flex-column align-items-start justify-content-center">
    <strong>{title}</strong>
    {Array.isArray(content) ? (
      <div className="d-flex flex-wrap gap-2">
        {content.map((item: any, index) =>
          isBadge ? (
            <CardBadge key={index} text={item.toUpperCase()} />
          ) : (
            <span key={index} className="me-2">
              {item}
            </span>
          ),
        )}
      </div>
    ) : isBadge ? (
      <CardBadge text={content} isAmount={true} />
    ) : (
      <span>{content}</span>
    )}
  </div>
)

const CallDescription = ({
                           description,
                           qualifications,
                           postedOn,
                           fundAmount,
                           sectors,
                           locationScope,
                           deadline,
                         }: CallDescriptionProps) => {
  const [regions = [], countries = []] = Array.isArray(locationScope) ? locationScope : [[], []]
  const formattedLocationScope = regions.length && countries.length
    ? `${regions.join(', ')}-${countries.join(', ')}`
    : regions.length
      ? regions.join(', ')
      : countries.length
        ? countries.join(', ')
        : '-'
  return (
    <Container fluid>
      <Row>
        <Col sm={12} md={8} className="border-end border-1 border-gray-200 pb-6">
          <h3 className="pt-3">Description</h3>
          <p>{description}</p>

          <h3>Qualifications and Requirements</h3>
          <ul className="ps-5">
            {qualifications.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </Col>
        <Col sm={12} md={4} className="px-0">
          <DetailSection title="Posted On" content={postedOn} />
          <DetailSection title="Fund Amount" content={fundAmount} isBadge={true} />
          <DetailSection title="Sectors" content={sectors} isBadge={true} />
          <DetailSection title="Location Scope" content={formattedLocationScope} />
          <DetailSection title="Deadline" content={deadline} />
        </Col>
      </Row>
    </Container>
  )
}

export default CallDescription