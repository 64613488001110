import React, { FC, useEffect, useState } from 'react'
import {
  createServiceProviderSelectors,
  useGetServiceProvidersQuery,
} from '../ServiceProvidersApiSlice'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../data/redux/Store'
import Pagination from '../../shared/components/Pagination'
import { Spinner } from 'react-bootstrap'
import { ServiceProviderCard } from '../components'
import useAuthToken from '../../../../hooks/useAuthToken'
import { Toolbar } from '../../../../_theme/layout/components/Toolbar'
import EntityListSearch from '../../shared/components/EntityListSearch'
import { ListPageFilters } from '../../shared/modals/ListPageFilters'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { toggleFiltersState } from '../../../../data/redux/settingsReducer'
import { INameIdPair } from '../../shared/interfaces'
import FilterButton from '../../shared/components/FilterButton'
import { IListPageFilters } from '../../shared/interfaces/IListPageFilters'
import { useGetSectorsQuery } from '../../companies/CompaniesApiSlice'

const ServiceProviders: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const routePage = searchParams.get('page') || '1'
  const CurrentPageSize = searchParams.get('pageSize') || '9'
  const currentSearchTerm = searchParams.get('searchTerm') || ''
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(parseInt(routePage))
  const [pageSize, setPageSize] = useState<number>(parseInt(CurrentPageSize))
  const isTokenAvailable = useAuthToken()
  const [searchTerm, setSearchTerm] = useState(currentSearchTerm)
  const filters = useSelector((state: RootState) => state.settings.providerFilters)
  const filtersStatus = useSelector((state: RootState) => state.settings.showFilters)
  const { data, error, isLoading, isFetching, isSuccess, isError } = useGetServiceProvidersQuery(
    { page: currentPage, pageSize: pageSize, searchTerm: searchTerm, ...filters },
    { skip: !isTokenAvailable }
  )
  const { data: sectorsState } = useGetSectorsQuery()
  const companySectorsList = Object.values(sectorsState?.entities || {}).filter(
    (type): type is INameIdPair => Boolean(type)
  )
  const { selectIds: selectServiceProviderIds } = createServiceProviderSelectors({
    page: currentPage,
    pageSize,
    searchTerm,
    ...filters,
  })
  const serviceProviderIds = useSelector((state: RootState) =>
    selectServiceProviderIds(state)
  ) as string[]
  const paginationMetadata = data?.paginationMetadata

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    navigate(`?page=${page}&pageSize=9`)
  }

  const handleResetSelectedPage = () => {
    setCurrentPage(1)
  }

  useEffect(() => {
    const newPageSize = parseInt(searchParams.get('pageSize') || '9')
    setPageSize(newPageSize)
  }, [searchParams])

  const handleFiltersModal = () => {
    setShowFiltersModal(!showFiltersModal)
  }

  let content
  if (isFetching) {
    content = (
      <div className='d-flex justify-content-center flex-column gap-3 align-items-center h-100'>
        <Spinner animation='border' variant='danger' />
        <p className='pt-0'>Loading...</p>
      </div>
    )
  } else if (isSuccess) {
    content =
      serviceProviderIds.length > 0 ? (
        <div className='row'>
          {serviceProviderIds.map((id) => (
            <ServiceProviderCard
              key={id}
              id={id}
              page={currentPage}
              pageSize={pageSize}
              searchTerm={searchTerm}
              filters={filters}
            />
          ))}
        </div>
      ) : (
        <div className='d-flex justify-content-right align-items-right vh-100'>
          <em>There are no service providers listed yet.</em>
        </div>
      )
  } else if (isError) {
    if ('status' in error) {
      const fetchError = error as { status: number; data: unknown; error: string }
      const errorCode = fetchError.status
      navigate(`/error/${errorCode}`)
    } else if ('message' in error) {
      content = (
        <div className='d-flex justify-content-center align-items-center vh-100'>
          {error.message}
        </div>
      )
    } else {
      content = (
        <div className='d-flex justify-content-center align-items-center vh-100'>
          Unknown error occurred
        </div>
      )
    }
  }

  return (
    <div className='d-flex flex-column h-100'>
      <Toolbar
        page='Service Providers'
        link='/services/providers/add'
        buttonTitle='Add New Service Provider'
      >
        <EntityListSearch setSearchTerm={setSearchTerm} handlePageChange={handlePageChange} />
        <FilterButton
          showFilters={showFiltersModal}
          handleFilterButtonClick={handleFiltersModal}
          hasWhiteBackground={true}
        />
      </Toolbar>
      <div className='flex-grow-1'>{content}</div>
      {serviceProviderIds.length > 0 && (
        <div className='flex-shrink-0'>
          {data && paginationMetadata && (
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(paginationMetadata.totalCount / paginationMetadata.pageSize)}
              onPageChange={handlePageChange}
              entriesCount={paginationMetadata.totalCount}
              entriesPerPage={paginationMetadata.pageSize}
            />
          )}
        </div>
      )}
      <ListPageFilters
        showModal={showFiltersModal}
        handleClose={handleFiltersModal}
        companySectorsList={companySectorsList}
        includeInvestmentAsk={false}
        includeFinancingTypes={false}
        entities='Service Providers'
        handleResetSelectedPage={handleResetSelectedPage}
      />
    </div>
  )
}

export { ServiceProviders }