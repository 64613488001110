import React from 'react'
import {Outlet} from 'react-router-dom'
import FinancierPagesHeader from './FinancierPagesHeader'

const FinancierPagesWrapper = () => {
  return (
    <div className="financier-pages-wrapper">
      <FinancierPagesHeader />
      <div className="mt-125px px-8">
        <Outlet />
      </div>
    </div>
  )
}

export {FinancierPagesWrapper}
