import {FC, useEffect, useMemo, useState} from 'react'
import Search from '../../shared/components/Search'
import {UploadServiceProviderDocument} from '../modals/UploadServiceProviderDocument'
import {fileExtensionIcons} from '../../shared/constants'
import {useParams} from 'react-router-dom'
import {
  useAddNewServiceProviderDocumentFolderMutation,
  useAddNewServiceProviderDocumentMutation,
  useDeleteDocumentFolderMutation,
  useDeleteServiceProviderDocumentMutation,
  useGetServiceProviderDocumentFoldersQuery,
  useGetServiceProviderDocumentsQuery,
  useUpdateServiceProviderDocumentFolderMutation,
} from '../ServiceProvidersApiSlice'
import {IProviderDocumentFolders, IProviderDocumentMetaData} from '../interfaces'
import {printDateWithOrdinal} from '../../../../utils/dateHelpers'
import {formatBytes} from '../../../../utils/numberHelpers'
import {trimString} from '../../../../utils/stringHelpers'
import {useDeleteDocumentMutation, useGetDocumentsQuery} from '../../documents/DocumentsApiSlice'
import {OptionsPopup} from '../../shared/components/OptionsPopup'
import {UpdateFolder} from '../../shared/components/UpdateFolder'
import {DeleteModal} from '../../shared/components/DeleteModal'
import {UploadDocument} from '../../shared/modals/UploadDocument'
import {CreateFolder} from '../../shared/modals/CreateFolder'

type ServiceProviderDocumentsListProps = {
  selectedFolder: {index: number; id: string; folderName: string; parentFolderId?: string | null}
  setSelectedFolder: (folder: {
    index: number
    id: string
    folderName: string
    parentFolderId?: string | null
  }) => void
}

const ServiceProviderDocumentsList: FC<ServiceProviderDocumentsListProps> = ({
  selectedFolder,
  setSelectedFolder,
}) => {
  const {id} = useParams<{id: string}>() as {id: string}
  const [searchTerm, setSearchTerm] = useState('')
  const [triggerDownload, setTriggerDownload] = useState(false)
  const [showUploadDocumentModal, setShowUploadDocumentModal] = useState(false)
  const [showDeleteFolderModal, setShowDeleteFolderModal] = useState(false)
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false)
  const [showDeleteFileModal, setShowDeleteFileModal] = useState(false)
  const [showEditFolderModal, setShowEditFolderModal] = useState<boolean>(false)
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null)
  const [selectedChildFolder, setSelectedChildFolder] = useState<{
    index: number
    id: string
    folderName: string
  }>({index: 0, id: '', folderName: ''})
  const [showFileOptionsModal, setShowFileOptionsModal] = useState(false)
  const [selectedEntityId, setSelectedEntityId] = useState('')
  const [folderHistory, setFolderHistory] = useState<
    Array<{
      index: number
      id: string
      folderName: string
      parentFolderId?: string | null
    }>
  >([])
  const [deleteDocument, {isLoading: isDeletingDocument}] = useDeleteDocumentMutation()
  const {data: providerDocumentsState} = useGetServiceProviderDocumentsQuery({
    providerId: id,
    folderId: selectedFolder.id,
    searchTerm,
  })
  const [deleteProviderDocument, {isLoading: isDeletingProviderDocument}] =
    useDeleteServiceProviderDocumentMutation()
  const [deleteFolder, {isLoading: deletingFolder}] = useDeleteDocumentFolderMutation()
  const {data: providerDocumentFoldersState, isLoading: isGettingFolders} =
    useGetServiceProviderDocumentFoldersQuery({
      providerId: id,
      searchTerm,
      parentFolderId: selectedFolder.id,
    })
  const {data: parentFolderState, isLoading: isGettingParentFolder} =
    useGetServiceProviderDocumentFoldersQuery({
      id: selectedFolder.parentFolderId ?? '',
      providerId: id,
    })
  const [saveDocumentMetaData, {isLoading: isSavingDocumentMetaData}] =
    useAddNewServiceProviderDocumentMutation()
  const [addProviderFolder, {isLoading: creatingProviderFolder}] =
    useAddNewServiceProviderDocumentFolderMutation()

  const parentFolder = selectedFolder.parentFolderId
    ? parentFolderState?.entities[selectedFolder.parentFolderId]
    : null
  console.log('parentFolder', parentFolder)
  const childFolders: IProviderDocumentFolders[] = Object.values(
    providerDocumentFoldersState?.entities || {}
  ) as IProviderDocumentFolders[]
  const providerDocuments: IProviderDocumentMetaData[] = Object.values(
    providerDocumentsState?.entities || {}
  ) as IProviderDocumentMetaData[]

  const [updateFolder, {isLoading: updatingFolder}] =
    useUpdateServiceProviderDocumentFolderMutation()

  const {data: documentsState, isFetching: gettingFile} = useGetDocumentsQuery([selectedEntityId], {
    skip: !triggerDownload,
  })
  const file = useMemo(
    () => documentsState?.entities[selectedEntityId],
    [documentsState, selectedEntityId]
  )

  useEffect(() => {
    if (triggerDownload && file) {
      const byteCharacters = atob(file.fileContent)
      const byteArray = new Uint8Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteArray[i] = byteCharacters.charCodeAt(i)
      }
      const blob = new Blob([byteArray], {type: file.fileType})
      const blobUrl = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = blobUrl
      link.download = file.fileName
      link.click()

      URL.revokeObjectURL(blobUrl)
      setTriggerDownload(false)
      setOpenDropdownId(null)
    }
  }, [triggerDownload, file])

  const documents = providerDocuments
    ? providerDocuments.map((document) => {
        const [name, extension] = document.fileName.split('.')
        return {
          ...document,
          documentName: name,
          documentExtension: extension,
          uploadedDate: printDateWithOrdinal(document.dateUploaded),
          size: formatBytes(document.fileSize),
        }
      })
    : []

  const handleFileOptionsClick = (id: string) => {
    setSelectedEntityId(id)
    setOpenDropdownId(id)
  }

  const handleBackClick = () => {
    if (folderHistory.length > 0) {
      const previousFolder = folderHistory.pop()
      if (previousFolder) {
        setFolderHistory([...folderHistory])
        setSelectedFolder(previousFolder)
      }
    } else {
      setSelectedFolder({
        index: 0,
        id: '',
        folderName: '',
        parentFolderId: '',
      })
    }
  }

  const handleUploadFileModal = () => {
    setShowUploadDocumentModal(!showUploadDocumentModal)
  }

  const handleCreateFolderModal = () => {
    setShowCreateFolderModal(!showCreateFolderModal)
  }

  const handleChildFolderClick = (folder: IProviderDocumentFolders) => {
    setFolderHistory([...folderHistory, selectedFolder])
    setSelectedFolder({
      index: selectedFolder.index + 1,
      id: folder.id,
      folderName: folder.folderName,
      parentFolderId: folder.parentFolderId,
    })
  }

  const handleChildFolderOptionsClick = (folder: {
    index: number
    id: string
    folderName: string
  }) => {
    if (selectedChildFolder?.folderName === '') {
      setSelectedChildFolder(folder)
    }
  }

  const handleDeleteFolder = async () => {
    try {
      await deleteFolder(selectedChildFolder?.id).unwrap()
      setSelectedChildFolder({index: 0, id: '', folderName: ''})
      handleDeleteFolderModal()
    } catch (error) {}
  }

  const handleUpdateFolder = async (name: string) => {
    try {
      await updateFolder({
        folderName: name,
        providerId: id,
        id: selectedChildFolder?.id,
        parentFolderId: selectedFolder?.id,
      }).unwrap()
      setSelectedChildFolder({index: 0, id: '', folderName: ''})
      handleEditFolderModal()
    } catch (error) {}
  }

  const handleEditFolderModal = () => {
    setShowEditFolderModal(!showEditFolderModal)
  }

  const handleDeleteModal = () => {
    setShowDeleteFileModal(!showDeleteFileModal)
  }

  const handleDownload = () => {
    setTriggerDownload(true)
  }

  const handleDeleteFolderModal = () => {
    setShowDeleteFolderModal(!showDeleteFolderModal)
  }
  const handleDeleteFile = async () => {
    try {
      await Promise.all([
        deleteDocument(selectedEntityId).unwrap(),
        deleteProviderDocument(selectedEntityId).unwrap(),
      ])
      setSelectedEntityId('')
      handleDeleteModal()
    } catch (error) {}
  }
  const handlePreview = () => {
    window.open(`/file-preview/${selectedEntityId}`, '_blank')
  }

  const handleUploadFile = async (res: any) => {
    try {
      const documentsMetaData = res.map((doc: any) => {
        return {
          fileName: doc.fileName,
          fileType: doc.fileType,
          serviceProviderFolderId: selectedEntityId,
          dateUploaded: doc.dateUploaded,
          fileSize: doc.fileSize,
          id: doc.id,
          providerId: id,
        }
      })
      await saveDocumentMetaData(documentsMetaData).unwrap()
      handleUploadFileModal()
    } catch (err) {
      console.log(err)
    }
  }
  const handleCreateFolder = async (name: string) => {
    try {
      await addProviderFolder({
        folderName: name,
        providerId: id,
        ...(selectedEntityId && {parentFolderId: selectedEntityId}),
      }).unwrap()
      handleCreateFolderModal()
    } catch (error) {}
  }

  return (
    <>
      <div className='card card-flush card-bordered mt-4 mb-4'>
        <div className='card-body py-5'>
          <div className='d-flex align-items-center justify-content-between position-relative py-3'>
            <div>
              <span className='fs-6 fw-bold hover-bg text-hover-danger' onClick={handleBackClick}>
                {selectedFolder.index > 1 ? (
                  <>
                    <i className='fas fa-arrow-left fs-8 me-1 text-dark'></i>{' '}
                    {trimString(parentFolder?.folderName ?? '', 12, true)}
                  </>
                ) : (
                  <>
                    <i className='fas fa-arrow-left fs-8 me-1 text-dark'></i> Documents
                  </>
                )}
              </span>
              /<span className='fs-7 fw-bold'>{selectedFolder?.folderName}</span>
            </div>
            <div className='d-flex align-items-center gap-3'>
              <Search setSearchTerm={setSearchTerm} />
              <div onClick={() => setSelectedEntityId(selectedFolder?.id)}>
                <OptionsPopup
                  selectedEntity={selectedEntityId}
                  setSelectedEntity={setSelectedEntityId}
                  isButton={true}
                  includeCreateFolder={true}
                  includeUploadFile={true}
                  handleUploadFileModal={handleUploadFileModal}
                  handleCreateFolderModal={handleCreateFolderModal}
                  showCreateFolderModal={showCreateFolderModal}
                  showUploadDocumentModal={showUploadDocumentModal}
                  handleUploadFile={handleUploadFile}
                  entity={'Service Provider'}
                />
              </div>
            </div>
          </div>
          <div className='table-like'>
            <div className='table-header fw-bold fs-6 border-bottom pb-4'>
              <div className='header-item'>Name</div>
              <div className='header-item'>Size</div>
              <div className='header-item'>Uploaded On</div>
              <div className='header-item'></div>
            </div>
            {childFolders.map((folder, index) => (
              <div
                key={folder.id}
                className={`table-row ${index < childFolders.length - 1 ? 'border-bottom' : ''}`}
              >
                <div className='row-item'>
                  <div
                    className='d-flex align-items-center gap-1 cursor-pointer hover-bg'
                    onClick={() => handleChildFolderClick(folder)}
                  >
                    <i className={`fa ${fileExtensionIcons['folder']} me-2`}></i>
                    <span>{folder.folderName}</span>
                  </div>
                </div>
                <div className='row-item'>{formatBytes(folder.totalFileSize)}</div>
                <div className='row-item'>{printDateWithOrdinal(folder.dateCreated)}</div>
                <div className='row-item'>
                  <div className='position-relative'>
                    <div
                      className='d-flex justify-content-end p-2 cursor-pointer'
                      onClick={() =>
                        handleChildFolderOptionsClick({
                          index,
                          id: folder.id,
                          folderName: folder.folderName,
                        })
                      }
                    >
                      <OptionsPopup
                        selectedEntity={selectedChildFolder}
                        setSelectedEntity={setSelectedChildFolder}
                        handleDelete={handleDeleteFolder}
                        handleUpdateEntity={handleUpdateFolder}
                        updatingEntity={updatingFolder}
                        showDeleteModal={showDeleteFolderModal}
                        showEditModal={showEditFolderModal}
                        deletingEntity={deletingFolder}
                        handleDeleteModal={handleDeleteFolderModal}
                        handleEditModal={handleEditFolderModal}
                        entity='Folder'
                        includeFilePreviewOption={false}
                        includeUpdateOption={true}
                        includeDeleteOption={true}
                        isEllipsis={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {documents.map((document, index) => (
              <div
                key={index}
                className={`table-row ${index < documents.length - 1 ? 'border-bottom' : ''}`}
              >
                <div className='row-item'>
                  <div className='d-flex align-items-center gap-1'>
                    <i className={`fa ${fileExtensionIcons[document.documentExtension]} me-2`}></i>
                    <span>{document.documentName}</span>
                  </div>
                </div>
                <div className='row-item'>{document.size}</div>
                <div className='row-item'>{document.uploadedDate}</div>
                <div className='row-item'>
                  <div className='position-relative'>
                    <div
                      className='d-flex justify-content-end p-2 cursor-pointer'
                      onClick={() => handleFileOptionsClick(document?.id)}
                    >
                      <OptionsPopup
                        selectedEntity={selectedEntityId}
                        setSelectedEntity={setSelectedEntityId}
                        deletingEntity={isDeletingDocument}
                        handleDeleteModal={handleDeleteModal}
                        entity='File'
                        handlePreview={handlePreview}
                        includeFilePreviewOption={true}
                        includeUpdateOption={false}
                        includeDownloadOption={true}
                        includeDeleteOption={true}
                        triggerDownload={triggerDownload}
                        gettingFile={gettingFile && openDropdownId === document.id}
                        handleDownload={handleDownload}
                        isEllipsis={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <CreateFolder
        showCreateFolderModal={showCreateFolderModal}
        handleCreateFolderModal={handleCreateFolderModal}
        handleCreateFolder={handleCreateFolder}
        isLoading={creatingProviderFolder}
      />
      <UpdateFolder
        showEditModal={showEditFolderModal}
        selectedFolder={selectedChildFolder}
        setSelectedFolder={setSelectedChildFolder}
        handleEditModal={handleEditFolderModal}
        isLoading={updatingFolder}
        handleUpdateFolder={handleUpdateFolder}
      />
      <DeleteModal
        showDeleteModal={showDeleteFileModal || showDeleteFolderModal}
        isLoading={isDeletingDocument || deletingFolder}
        handleDelete={showDeleteFolderModal ? handleDeleteFolder : handleDeleteFile}
        item={showDeleteFolderModal ? 'Folder' : 'File'}
        handleClose={showDeleteFolderModal ? handleDeleteFolderModal : handleDeleteModal}
      />
      <UploadDocument
        showUploadDocumentModal={showUploadDocumentModal}
        handleUploadFileModal={handleUploadFileModal}
        handleUploadFile={handleUploadFile}
        isSavingDocumentMetaData={isSavingDocumentMetaData}
      />
    </>
  )
}

export default ServiceProviderDocumentsList
