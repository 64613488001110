type props = {
  showFilters: boolean
  handleFilterButtonClick: () => void
  hasWhiteBackground?:boolean
}
const FilterButton = ({showFilters, handleFilterButtonClick, hasWhiteBackground = false}: props) => {
  return (
    <button
      className={`d-flex align-items-center justify-content-evenly gap-2 button-transparent text-bg-transparent w-100px h-40px ${
        showFilters ? 'text-danger text-bg-secondary' : ''
      } ${
        hasWhiteBackground ? 'bg-white' : ''
      }`}
      onClick={handleFilterButtonClick}
    >
      <i className='bi bi-filter fs-2 fw-bolder text-danger' aria-hidden='true'></i>
      <span>Filters</span>
    </button>
  )
}

export default FilterButton