import React from 'react'
import {Container, Row, Col, Tab, Nav, Button, ListGroup} from 'react-bootstrap'
import Header from '../components/HeaderPage'
import Content from '../components/candidates-components/ContentComponent'

const CandidatesDetailPage: React.FC = () => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <Header />
        </Col>
      </Row>
      <Row className="mt-3 bg-white">
        <Content />
      </Row>
    </Container>
  )
}

export default CandidatesDetailPage