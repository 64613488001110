import {useParams} from 'react-router-dom'
import {useGetCompanyDetailsQuery, useGetCompanyContactsQuery} from '../CompaniesApiSlice'
import Loading from '../../shared/components/Loading'
import {FC} from 'react'
import {EditCompanyContactInformation} from '../modals/EditCompanyContactInformation'
import EditButton from '../../shared/components/EditButton'
import LineSpan from '../../shared/components/LineSpan'

type CompanyContactInformationProps = {
  showModal: boolean
  handleClose: () => void
}

const CompanyContactInformation: FC<CompanyContactInformationProps> = ({
                                                                         showModal,
                                                                         handleClose,
                                                                       }) => {
  const {id} = useParams<{id: string}>() as {id: string}
  const {data: companyState} = useGetCompanyDetailsQuery(id)

  const {data: companyContactsState, isLoading: companyContactsLoading} =
    useGetCompanyContactsQuery(id)

  let companyContact = null
  if (companyContactsState?.entities) {
    const contactsArray = Object.values(companyContactsState?.entities)
    companyContact = contactsArray.length > 0 ? contactsArray[0] : null
  }

  const companyDetails = companyState?.entities[id]

  if (companyContactsLoading) return <Loading />
  return (
    <div className="position-relative">
      <div className="card card-flush mb-4">
        <div className="card-body p-0 px-6 py-5">
          <div className="row">
            <div className="col-md-6">
              <span className="fw-bolder text-dark fs-8 m-0">Country</span>
              <p className="fs-7 text-justify">{companyDetails?.country}</p>
            </div>
            <div className="col-md-6">
              <span className="fw-bolder text-dark fs-8 m-0">Region</span>
              <p className="fs-7 text-justify">{companyDetails?.region || <LineSpan />}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <span className="fw-bolder text-dark fs-8 m-0">District</span>
              <p className="fs-7 text-justify">{companyDetails?.district || <LineSpan />}</p>
            </div>
            <div className="col-md-6">
              <span className="fw-bolder text-dark fs-8 m-0">Address</span>
              <p className="fs-7 text-justify">{companyDetails?.address || <LineSpan />}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <span className="fw-bolder text-dark fs-8 m-0">Facebook</span>
              <p className="fs-7 text-justify">{companyDetails?.facebook || <LineSpan />}</p>
            </div>
            <div className="col-md-6">
              <span className="fw-bolder text-dark fs-8 m-0">LinkedIn</span>
              <p className="fs-7 text-justify">{companyDetails?.linkedIn || <LineSpan />}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <span className="fw-bolder text-dark fs-8 m-0">Twitter</span>
              <p className="fs-7 text-justify">{companyDetails?.twitter || <LineSpan />}</p>
            </div>
            <div className="col-md-6">
              <span className="fw-bolder text-dark fs-8 m-0">Website</span>
              <p className="fs-7 text-justify">{companyDetails?.website || <LineSpan />}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <span className="fw-bolder text-dark fs-8 m-0">Name of Contact Person</span>
              <p className="fs-7 text-justify">{companyContact?.companyContactPersonName || <LineSpan />}</p>
            </div>
            <div className="col-md-6">
              <span className="fw-bolder text-dark fs-8 m-0">Email</span>
              <p className="fs-7 text-justify">{companyContact?.companyContactPersonEmail || <LineSpan />}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <span className="fw-bolder text-dark fs-8 m-0">Phone Number</span>
              <p className="fs-7 text-justify">
                {companyContact?.companyContactPersonTelephone || <LineSpan />}
              </p>
            </div>
            <div className="col-md-6">
              <span className="fw-bolder text-dark fs-8 m-0">Address</span>
              <p className="fs-7 text-justify">{companyContact?.companyContactPersonAddress || <LineSpan />}</p>
            </div>
          </div>
        </div>
      </div>
      <EditButton handleClose={handleClose} />
      <EditCompanyContactInformation showModal={showModal} handleClose={handleClose} />
    </div>
  )
}

export default CompanyContactInformation
