import { EntityId } from '@reduxjs/toolkit'
import React, {useState} from 'react'

type props = {
  stages: EntityId[]
  selectedStage: string
}

const StatusBar: React.FC<props> = ({stages, selectedStage}) => {
  const [activeTab] = useState(selectedStage)
  

  return (
    <div className='progress-tracker max-width-100'>
      {stages.map(stage => (
        <button
          key={stage}
          className={`step ${activeTab === stage ? 'active' : ''}`}
        >
          {stage.toString().toUpperCase()}
        </button>
      ))}
    </div>
  )
}

export default StatusBar