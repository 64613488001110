import {useParams} from 'react-router-dom'
import {useGetCompanyDetailsQuery} from '../CompaniesApiSlice'
import {FC} from 'react'
import {EditCompanyOperation} from '../modals'
import getEntitySize from '../../../../utils/getEntitySize'
import LineSpan from '../../shared/components/LineSpan'
import {numberCurrencyPairs} from '../../shared/constants'
import {stripHTML} from '../../../../utils/stringHelpers'

type CompanyOperationProps = {
  showModal: boolean
  handleClose: () => void
}

export const CompanyOperations: FC<CompanyOperationProps> = ({showModal, handleClose}) => {
  const {id} = useParams<{id: string}>() as {id: string}
  const {data: companyState} = useGetCompanyDetailsQuery(id)
  const companyDetails = companyState?.entities[id]

  const summary = stripHTML(companyDetails?.summary ?? '') as string

  return (
    <div className='position-relative'>
      <div className='card card-flush mb-4'>
        <div className='card-body px-6 p-0'>
          <div className='row mb-4'>
            <div className='col-md-12 pt-5'>
              <span className='fw-bolder text-dark fs-8 m-0'>About Company</span>
              <p className='fs-7 text-justify'>{summary || <LineSpan />}</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <span className='fw-bolder text-dark fs-8 m-0'>Slogan</span>
              <p className='fs-7 text-justify'>{companyDetails?.slogan || <LineSpan />}</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <span className='fw-bolder text-dark fs-8 m-0'>Year of Incorporation</span>
              <p className='fs-7 text-justify'>
                {companyDetails?.yearOfIncorporation || <LineSpan />}
              </p>
            </div>
            <div className='col-md-6'>
              <span className='fw-bolder text-dark fs-8 m-0'>Sub-sector</span>
              <p className='fs-7 text-justify'>{companyDetails?.subSector || <LineSpan />}</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <span className='fw-bolder text-dark fs-8 m-0'>Product Line</span>
              <p className='fs-7 text-justify'>{companyDetails?.productLine || <LineSpan />}</p>
            </div>
            <div className='col-md-6'>
              <span className='fw-bolder text-dark fs-8 m-0'>Company Size</span>
              <p className='fs-7 text-justify'>
                {companyDetails?.numberOfEmployees != null ? (
                  getEntitySize(companyDetails.numberOfEmployees)
                ) : (
                  <LineSpan />
                )}
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <span className='fw-bolder text-dark fs-8 m-0'>No of Employees</span>
              <p className='fs-7 text-justify'>
                {companyDetails?.numberOfEmployees != null ? (
                  companyDetails.numberOfEmployees.toLocaleString()
                ) : (
                  <LineSpan />
                )}
              </p>
            </div>
            <div className='col-md-6'>
              <span className='fw-bolder text-dark fs-8 m-0'>Number of Female Employees</span>
              <p className='fs-7 text-justify'>
                {companyDetails?.numberOfFemaleEmployees != null ? (
                  companyDetails.numberOfFemaleEmployees.toLocaleString()
                ) : (
                  <LineSpan />
                )}
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <span className='fw-bolder text-dark fs-8 m-0'>No of Youth Employees</span>
              <p className='fs-7 text-justify'>
                {companyDetails?.numberOfYouthEmployees != null ? (
                  companyDetails.numberOfYouthEmployees.toLocaleString()
                ) : (
                  <LineSpan />
                )}
              </p>
            </div>
            <div className='col-md-6'>
              <span className='fw-bolder text-dark fs-8 m-0'>
                Average Income earned by beneficiaries
              </span>
              <p className='fs-7 text-justify'>
                {companyDetails?.averageIncomeByBeneficiaries != null ? (
                  `${
                    numberCurrencyPairs[
                      Number(companyDetails?.averageIncomeByBeneficiariesCurrency) ?? 0
                    ]
                  } ${companyDetails.averageIncomeByBeneficiaries.toLocaleString()}`
                ) : (
                  <LineSpan />
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <button
        className='position-absolute btn btn-link p-0 company-edit-button'
        onClick={handleClose}
      >
        <i className='fas fa-pen text-danger'></i>
      </button>
      <EditCompanyOperation showModal={showModal} handleClose={handleClose} />
    </div>
  )
}
