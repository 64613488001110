import React, {FC, useState} from 'react'
import {useSelector} from 'react-redux'
import {FinancierCard} from '../components/FinancierCard'
import {RootState} from '../../../../data/redux/Store'
import {Spinner} from 'react-bootstrap'
import Pagination from '../../shared/components/Pagination'
import useAuthToken from '../../../../hooks/useAuthToken'
import {Toolbar} from '../../../../_theme/layout/components/Toolbar'
import {useGetFinanciersQuery, createFinancierSelectors} from '../FinanciersApiSlice'
import FinancierFilters from '../components/FinancierFilters'
import FinancierSearch from '../components/FinancierSearch'
import EntityListSearch from '../../shared/components/EntityListSearch'
import {useNavigate} from 'react-router'
import FilterButton from '../../shared/components/FilterButton'
import {ListPageFilters} from '../../shared/modals/ListPageFilters'
import {useGetSectorsQuery} from '../../companies/CompaniesApiSlice'
import {INameIdPair} from '../../shared/interfaces'

const Financiers: FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [pageSize] = useState<number>(9)

  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false)
  const isTokenAvailable = useAuthToken()
  const navigate = useNavigate()
  const filters = useSelector((state: RootState) => state.settings.financierFilters)
  const {data, error, isFetching, isSuccess, isError} = useGetFinanciersQuery(
    {page: currentPage, pageSize: pageSize, searchTerm: searchTerm, ...filters},
    {skip: !isTokenAvailable},
  )
  const {data: sectorsState} = useGetSectorsQuery()
  const companySectorsList = Object.values(sectorsState?.entities || {}).filter(
    (type): type is INameIdPair => Boolean(type),
  )

  const {selectIds: selectFinanciersIds} = createFinancierSelectors({
      page: currentPage,
      pageSize,
      searchTerm,
      ...filters,
    },
  )
  const financiersIds = useSelector((state: RootState) => selectFinanciersIds(state)) as string[]
  const paginationMetadata = data?.paginationMetadata
  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const handleResetSelectedPage = () => {
    setCurrentPage(1)
  }

  const handleFiltersModal = () => {
    setShowFiltersModal(!showFiltersModal)
  }

  let content
  if (isFetching) {
    content = (
      <div className="d-flex justify-content-center flex-column gap-3 align-items-center h-100">
        <Spinner animation="border" variant="danger" />
        <p className="pt-0">Loading...</p>
      </div>
    )
  } else if (isSuccess) {
    content =
      financiersIds.length > 0 ? (
        <div className="row">
          {financiersIds.map((id) => (
            <FinancierCard
              key={id}
              id={id}
              page={currentPage}
              pageSize={pageSize}
              searchTerm={searchTerm}
              filters={filters}
            />
          ))}
        </div>
      ) : (
        <div className="d-flex justify-content-right align-items-right vh-100">
          <em>There are no financiers listed yet.</em>
        </div>
      )
  } else if (isError) {
    if ('status' in error) {
      const fetchError = error as {status: number; data: unknown; error: string}
      const errorCode = fetchError.status
      navigate(`/error/${errorCode}`)
    } else if ('message' in error) {
      content = (
        <div className="d-flex justify-content-center align-items-center vh-100">
          {error.message}
        </div>
      )
    } else {
      content = (
        <div className="d-flex justify-content-center align-items-center vh-100">
          Unknown error occurred
        </div>
      )
    }
  }

  return (
    <div className="d-flex flex-column h-100">
      <Toolbar page="Financiers" link="/financiers/add" buttonTitle="Add New Financier">
        <EntityListSearch setSearchTerm={setSearchTerm} handlePageChange={handlePageChange} />
        <FilterButton
          showFilters={showFiltersModal}
          handleFilterButtonClick={handleFiltersModal}
          hasWhiteBackground={true}
        />
      </Toolbar>
      <div className="flex-grow-1">{content}</div>
      <div className="flex-shrink-0">
        {data && paginationMetadata && (
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(paginationMetadata.totalCount / paginationMetadata.pageSize)}
            onPageChange={handlePageChange}
            entriesCount={paginationMetadata.totalCount}
            entriesPerPage={pageSize}
          />
        )}
      </div>
      <ListPageFilters
        showModal={showFiltersModal}
        handleClose={handleFiltersModal}
        companySectorsList={companySectorsList}
        includeInvestmentAsk={false}
        includeFinancingTypes={false}
        includeYearsOfOperation={false}
        includeStartAndEndDate={true}
        includeSectors={false}
        entities="Financiers"
        handleResetSelectedPage={handleResetSelectedPage}
      />
    </div>
  )
}

export {Financiers}
