type DetailsHeaderNavigationBarProps = {
  tab: {
    name: string
  }
  selectedTab: string
  setSelectedTab: (tab: string) => void
}
const DetailsHeaderNavigationBar: React.FC<DetailsHeaderNavigationBarProps> = ({
  tab,
  selectedTab,
  setSelectedTab,
}) => {
  return (
    <div className='d-flex gap-2 fw-bold'>
      <div
        className='pb-2 cursor-pointer'
        style={{
          color: selectedTab === tab.name ? '#EC345C' : '#000',
          borderBottom: selectedTab === tab.name ? '2px solid #EC345C' : 'none',
        }}
        onClick={() => setSelectedTab(tab.name)}
      >
        {tab.name}
      </div>
    </div>
  )
}

export default DetailsHeaderNavigationBar
