import React, {FC} from 'react'
import Modal from 'react-bootstrap/Modal'
import {Formik, Form,Field} from 'formik'
import * as Yup from 'yup'
import {KTSVG} from '../../../../_theme/helpers'
import {useParams} from 'react-router-dom'
import {useAddNewDocumentFolderMutation} from '../CompaniesApiSlice'

type Props = {
  showCreateFolderModal: boolean
  handleCreateFolderModal: () => void
  parentFolderId?:string
}
const FolderFormInitialValues = {
  folderName: '',
}

const validationSchema = Yup.object().shape({
  folderName: Yup.string().required('Folder name is required'),
})

export const CreateCompanyDocumentsFolder: React.FC<Props> = ({handleCreateFolderModal, showCreateFolderModal,parentFolderId = ''}) => {
  const {id} = useParams<{id: string}>() as {id: string}
  const [addFolder, {isLoading}] = useAddNewDocumentFolderMutation()
  const handleSave = async (values: any) => {
    try {
      await addFolder({
        folderName: values.folderName,
        companyId: id,
        ...(parentFolderId && { parentFolderId }),
      }).unwrap()
      handleCreateFolderModal()
    } catch (error) {}
  }

  return (
    <>
      <Modal
        id='createFolderModal'
        aria-hidden={true}
        dialogClassName='h-auto mt-20'
        tabIndex={-1}
        show={showCreateFolderModal}
        onHide={handleCreateFolderModal}
        centered
      >
        <div className='modal-content' role='document'>
          <Formik
            initialValues={FolderFormInitialValues}
            validationSchema={validationSchema}
            onSubmit={handleSave}
          >
            {({errors, touched, isValid, values}) => (
              <Form>
                <div className='modal-header'>
                  <h5 className='modal-title ps-1' id='createFolderModalLabel'>
                    Create Folder
                  </h5>
                  <div
                    className='btn btn-sm btn-icon btn-active-color-danger'
                    data-bs-dismiss='modal'
                    onClick={handleCreateFolderModal}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                  </div>
                </div>
                <div className='modal-body d-flex flex-column align-items-start justify-content-center'>
                  <Field
                    type='text'
                    name='folderName'
                    className='form-control'
                    placeholder='Folder Name'
                  />
                  {touched?.folderName && (
                    <div className='text-danger mt-2'>{errors?.folderName}</div>
                  )}
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-outline btn-sm btn-outline-danger btn-active-light-danger'
                    onClick={handleCreateFolderModal}
                  >
                    Close
                  </button>
                  <button type='submit' className='btn btn-danger btn-sm' disabled={!isValid || isLoading}>
                    <span className='indicator-label'>
                      {!isLoading ? (
                        'Create'
                      ) : (
                        <span className='spinner-border h-15px w-15px align-middle text-white-400'></span>
                      )}
                    </span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  )
}
