import {Link, useParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_theme/helpers'

export const ErrorsPageHeaders = () => {
  const {id} = useParams<{id: string}>()

  return (
    <section className='fixed-top'>
      <div className='bg-black border-bottom d-flex align-items-center justify-content-between py-5 px-12'>
        <Link to={`/`}>
          <img
            alt='Logo'
            className='h-25px logo'
            src={toAbsoluteUrl('/media/logos/logo-red-white.png')}
          />
        </Link>
      </div>
    </section>
  )
}
