import React, {FC, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {FallbackView} from '../../_theme/layout/components'
import {MasterLayout} from '../../_theme/layout/MasterLayout'
import {SystemUsers} from '../features/users/pages/SystemUsers'
import {Overview} from '../features/overview/pages'
import {Companies, CompanyDetails, CreateCompany} from '../features/companies/pages'
import {Financiers, CreateFinancier, FinancierDetails} from '../features/financiers/pages'
import {
  CreateServiceProvider,
  ServiceProviderDetails,
  ServiceProviders,
} from '../features/serviceProviders/pages'
import Settings from '../features/settings/pages/Settings'
import {ErrorsPage} from '../features/errors/ErrorsPage'

const PrivateRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/companies?page=1&pageSize=6' />} />
        <Route path='/overview' element={<Overview />} />
        <Route index element={<Navigate to='/companies' />} />
        <Route
          path='companies/*'
          element={
            <Routes>
              <Route index element={<Companies />} />
              <Route path='add' element={<CreateCompany />} />
              <Route path=':id' element={<CompanyDetails />} />
              {/* Catch-all for invalid company subroutes */}
              <Route path='*' element={<Navigate to='/error/404' />} />
            </Routes>
          }
        />
        {/* Other routes */}
        <Route path='/financiers' element={<Financiers />} />
        <Route path='/financiers/add' element={<CreateFinancier />} />
        <Route path='/financiers/:id' element={<FinancierDetails />} />
        <Route path='/services/providers' element={<ServiceProviders />} />
        <Route path='/services/providers/add' element={<CreateServiceProvider />} />
        <Route path='/services/providers/:id' element={<ServiceProviderDetails />} />
        <Route path='/users' element={<SystemUsers />} />
        <Route path='/settings' element={<Settings />} />
        {/* <Route path='/' element={<Navigate to='/companies' />} /> */}
        {/* Catch-all for invalid routes */}

        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
      <Route path='/error/:errorCode' element={<ErrorsPage />} />
    </Routes>
  )
}

export {PrivateRoutes}
