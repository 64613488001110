import {FC, useEffect, useState} from 'react'
import FinancierHeader from '../components/FinancierHeader'
import FinancierGeneralInformation from '../components/FinancierGeneralInformation'
import FinancierFinancials from '../components/FinancierFinancials'
import FinancierDeals from '../components/FinancierDeals'
import FinancierOpportunities from '../components/FinancierOpportunities'
import FinancierContactInformation from '../components/FinancierContactInformation'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {getPaginationState} from '../../../../utils/getPaginationState'
import {Toolbar} from '../../../../_theme/layout/components/Toolbar'
import FinancierDocumentsList from '../components/FinancierDocumentsList'
import {FinancierDocumentFolderCard} from '../components/FinancierDocumentFolderCard'
import {useSelector} from 'react-redux'
import {createFinancierSelectors, useGetFinancierDetailsQuery} from '../FinanciersApiSlice'
import {RootState} from '../../../../data/redux/Store'
import Loading from '../../shared/components/Loading'
import {FetchBaseQueryError} from '@reduxjs/toolkit/query'

export const FinancierDetails = () => {
  const {id} = useParams<{id: string}>() as {id: string}
  const navigate = useNavigate()
  const location = useLocation()
  const {currentPage, pageSize} = getPaginationState(location)
  const [selectedTab, setSelectedTab] = useState('General Information')

  const {selectById: selectFinancierById} = createFinancierSelectors({
    page: currentPage,
    pageSize,
  })
  const financier = useSelector((state: RootState) => selectFinancierById(state, id || ''))
  const {
    data: financierState,
    isLoading: financierDetailsLoading,
    error,
  } = useGetFinancierDetailsQuery(id)

  const [showModal, setShowModal] = useState(false)
  const [openFolder, setOpenFolder] = useState(false)
  const [selectedFolder, setSelectedFolder] = useState<{
    index: number
    id: string
    folderName: string
  }>({index: 0, id: '', folderName: ''})

  const handleFolderToggle = () => {
    setOpenFolder(!openFolder)
  }
  const handleClose = () => {
    setShowModal(!showModal)
  }

  useEffect(() => {
    if (error) {
      const errorCode = (error as FetchBaseQueryError)?.status?.toString()
      navigate(`/error/${errorCode}`)
    }
  }, [error, navigate])

  if (financierDetailsLoading) {
    return <Loading />
  }

  // useEffect(() => {
  //   if (!financier) {
  //     navigate('/error')
  //   }
  // }, [financier, navigate])

  // if (!financier) {
  //   return null
  // }

  return (
    <>
      <Toolbar page='View Financier Details' link='/financiers' buttonTitle='Back'></Toolbar>

      <div className='row'>
        <div className='col-lg-12'>
          <FinancierHeader
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            currentPage={currentPage}
            pageSize={pageSize}
          />
        </div>
        <div className='col-lg-12'>
          <div className='card card-flush shadow-sm mt-5'>
            <div className='card-body p-0'>
              {selectedTab === 'General Information' && (
                <FinancierGeneralInformation
                  handleClose={handleClose}
                  showModal={showModal}
                  currentPage={currentPage}
                  pageSize={pageSize}
                />
              )}
              {selectedTab === 'Financials' && (
                <FinancierFinancials
                  handleClose={handleClose}
                  showModal={showModal}
                  currentPage={currentPage}
                  pageSize={pageSize}
                />
              )}
              {selectedTab === 'Contact Information' && (
                <FinancierContactInformation
                  handleClose={handleClose}
                  showModal={showModal}
                  currentPage={currentPage}
                  pageSize={pageSize}
                />
              )}
              {selectedTab === 'Documents' &&
                (openFolder ? (
                  <FinancierDocumentsList
                    selectedFolder={selectedFolder}
                    handleFolderToggle={handleFolderToggle}
                    showModal={showModal}
                    handleClose={handleClose}
                  />
                ) : (
                  <FinancierDocumentFolderCard
                    handleFolderToggle={handleFolderToggle}
                    setSelectedFolder={setSelectedFolder}
                    showModal={showModal}
                    handleClose={handleClose}
                    selectedFolder={selectedFolder}
                  />
                ))}

              {selectedTab === 'Deals' && <FinancierDeals />}
              {selectedTab === 'Opportunities' && <FinancierOpportunities />}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
