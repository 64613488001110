import React, {useEffect, useRef} from 'react'
import {toSvg} from 'jdenticon'

interface IdenticonAvatarProps {
  identifier: string;
  size?: number;
}

const IdenticonAvatar: React.FC<IdenticonAvatarProps> = ({identifier, size = 120}) => {
  const avatarRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (avatarRef.current) {
      avatarRef.current.innerHTML = toSvg(identifier, size)
    }
  }, [identifier, size])

  return (
    <div
      ref={avatarRef}
      style={{
        width: size,
        height: size,
        borderRadius: '50%', // Make it circular
        overflow: 'hidden',
      }}
    />
  )
}

export default IdenticonAvatar
