import {intToPercentage} from '../../../../utils/numberHelpers'

interface ProgressBarProps {
  value: number;
  title: string;
  total?: number;
  bold?: boolean;
  isPercentage?: boolean;
}

const ProgressBar = ({value, title, total = 6, bold = false, isPercentage = true}: ProgressBarProps) => {
  const percentage = intToPercentage(value, total)
  return (
    <>
      <div className="d-flex align-items-center flex-column mt-1 w-100">
        <div className={`d-flex justify-content-between fs-7 w-100 mt-auto mb-1 ${bold ? 'fw-bolder' : ''}`}>
          <span>{title}</span>
          <span>{isPercentage ? `${percentage}%` : `${value}/${total}`}</span>
        </div>
        <div className="h-4px w-100 bg-light mb-1">
          <div className="bg-success rounded h-4px" role="progressbar" style={{width: `${percentage}%`}}></div>
        </div>
      </div>
    </>
  )
}

export default ProgressBar
