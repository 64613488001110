import React from 'react'
import {useNavigate, useParams} from 'react-router-dom'

const Header: React.FC = () => {
  const navigate = useNavigate()
  const {financierId, callId} = useParams()

  return (
    <div className='my-3 d-flex align-items-center'>
      <h2 className='cursor-pointer' onClick={() => navigate(`/financier/${financierId}/calls/${callId}`, { state: { fromHeader: true } })}>
        <i className='fas fa-arrow-left text-dark pe-5'></i>
        Candidates
      </h2>
    </div>
  )
}

export default Header
