import React, {useState} from 'react'
import Search from '../../shared/components/Search'
import CardBadge from '../../shared/components/CardBadge'
import {useGetServiceProviderProjectsQuery} from '../../serviceProviders/ServiceProvidersApiSlice'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import Loading from '../../shared/components/Loading'
import useAuthToken from '../../../../hooks/useAuthToken'
import {formatDateToLocaleString} from '../../../../utils/dateHelpers'
import {trimString} from '../../../../utils/stringHelpers'
import {useGetFinancierCallsQuery} from '../FinanciersApiSlice'
import {IFinancierCall} from '../interfaces'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../data/redux/Store'
import FilterButton from '../../shared/components/FilterButton'
import EntityListSearch from '../../shared/components/EntityListSearch'
import {ListPageFilters} from '../../shared/modals/ListPageFilters'
import {useGetSectorsQuery} from '../../companies/CompaniesApiSlice'
import {INameIdPair} from '../../shared/interfaces'


const FinancierOpportunities = () => {
  const {id} = useParams<{id: string}>() as {id: string}
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [searchTerm, setSearchTerm] = useState('')
  const routePage = searchParams.get('page') || '1'
  const [currentPage, setCurrentPage] = useState<number>(parseInt(routePage))
  const [allChecked, setAllChecked] = useState(false)
  const [checkedItems, setCheckedItems] = useState<{[key: string]: boolean}>({})
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false)
  const filters = useSelector((state: RootState) => state.settings.callFilters)
  // const {data: serviceProviderProjectsState, isLoading} = useGetServiceProviderProjectsQuery(id)
  const isTokenAvailable = useAuthToken()
  const {data, error, isLoading, isFetching, isSuccess, isError} = useGetFinancierCallsQuery(
    {id: id || '', searchParams: filters},
    {skip: !isTokenAvailable},
  )

  const {data: sectorsState} = useGetSectorsQuery()
  const companySectorsList = Object.values(sectorsState?.entities || {}).filter(
    (type): type is INameIdPair => Boolean(type),
  )

  const callsList = Object.values(data?.entities?.entities || {}).filter(Boolean) as IFinancierCall[]

  const handleFiltersModal = () => {
    setShowFiltersModal(!showFiltersModal)
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    navigate(`?page=${page}&pageSize=6`)
  }

  const handleResetSelectedPage = () => {
    setCurrentPage(1)
  }


  const projectStages = [
    {stage: 'In Progress', value: 0},
    {stage: 'Completed', value: 1},
  ]


  const handleItemCheckedChange = (e: any, id: any) => {
    const {checked} = e.target
    setCheckedItems((prevCheckedItems: any) => ({
      ...prevCheckedItems,
      [id]: checked,
    }))
  }

  return (
    <>
      <div className="py-5 px-6">
        <div className="d-flex align-items-center justify-content-between position-relative">
          <span className="fw-bold text-dark fs-6">{'Financier Opportunities'}</span>
          <div className="d-flex align-items-center gap-2">
            <EntityListSearch setSearchTerm={setSearchTerm} handlePageChange={handlePageChange} />
            <FilterButton
              showFilters={showFiltersModal}
              handleFilterButtonClick={handleFiltersModal}
              hasWhiteBackground={true}
            />
          </div>
        </div>
        <div className="table-responsive pt-7">
          <table className="table .table-row-bordered table-row-gray-300 gs-1 gy-3 gx-7">
            <thead>
            <tr className="fw-bold fs-7 text-gray-800 border-bottom border-gray-200">
              <th className="fw-bolder px-0">Opportunity Name</th>
              <th className="fw-bolder px-0">Start Date</th>
              <th className="fw-bolder px-0">End Date</th>
              <th className="fw-bolder px-0">Maximum Amount</th>
              <th className="fw-bolder px-0">Applicants</th>
              <th className="fw-bolder px-0">Action</th>
            </tr>
            </thead>
            <tbody>
            {isFetching ? (
              <tr>
                <td colSpan={7}>
                  <div className="d-flex justify-content-center">
                    <Loading />
                  </div>
                </td>
              </tr>
            ) : (
              callsList &&
              callsList.map((project: any) => (
                <tr key={project.id} className="border-bottom border-gray-200">
                  {/* <td className='pb-2'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm'>
                        <input
                          className='form-check-input w-15px h-15px'
                          type='checkbox'
                          checked={checkedItems[project.id] || false}
                          onChange={(e) => handleItemCheckedChange(e, project.id)}
                          id={`flexRadioLg-${project.id}`}
                        />
                      </div>
                    </td> */}
                  <td className="fs-7 fw-bold px-0">{project.name}</td>
                  <td
                    className="ps-0 pe-0">{project.date_uploaded ? formatDateToLocaleString(project.date_uploaded) : 'NULL'}</td>
                  <td className="ps-0">{project.deadline ? formatDateToLocaleString(project.deadline) : 'NULL'}</td>
                  <td className="ps-0">{project.currency} {project.call_amount}</td>
                  <td className="ps-0">{project.application_count}</td>
                  {/* <td className='ps-0'>
                      <CardBadge
                        text={
                          projectStages.find((stage) => stage.value === project.projectStage)
                            ?.stage ?? ''
                        }
                      />
                    </td> */}
                  <td className="ps-3 cursor-pointer">
                    <i className="bi bi-eye"></i>
                  </td>
                </tr>
              ))
            )}
            </tbody>
          </table>
        </div>
        <ListPageFilters
          showModal={showFiltersModal}
          handleClose={handleFiltersModal}
          companySectorsList={companySectorsList}
          includeInvestmentAsk={false}
          includeFinancingTypes={false}
          includeYearsOfOperation={false}
          includeStartAndEndDate={true}
          includeSectors={false}
          entities="Calls"
          handleResetSelectedPage={handleResetSelectedPage}
        />
      </div>
    </>
  )
}

export default FinancierOpportunities
