import {useParams} from 'react-router-dom'
import {
  useGetCompanyDetailsQuery,
  useGetCompanyFinancialsQuery,
} from '../CompaniesApiSlice'
import Loading from '../../shared/components/Loading'
import {FC} from 'react'
import {EditCompanyFinancials} from '../modals'
import EditButton from '../../shared/components/EditButton'
import LineSpan from '../../shared/components/LineSpan'
import {ICompanyFinancingAsks} from '../interfaces'

type CompanyFinancialsProps = {
  showModal: boolean
  handleClose: () => void
}

const CompanyFinancials: FC<CompanyFinancialsProps> = ({showModal, handleClose}) => {
  const {id} = useParams<{id: string}>() as {id: string}
  const currencies: any = {
    0: 'USD',
    1: 'UGX',
    2: 'EUR',
  }
  const suitability: any = {
    1: 'Yes',
    2: 'No',
    3: <LineSpan />,
  }

  const {data: companyState} = useGetCompanyDetailsQuery(id)
  const {data: companyFinancialsState, isLoading: companyFinancialsLoading} =
    useGetCompanyFinancialsQuery(id)
  const [companyFinancial] = Object.values(companyFinancialsState?.entities ?? {}) as ICompanyFinancingAsks[]
  const companyDetails = companyState?.entities[id]
  let netProfitMargin: number = 0
  if (companyDetails) {
    netProfitMargin = companyDetails.annualRevenue
      ? (companyDetails.netProfit / companyDetails.annualRevenue) * 100
      : 0
  }

  if (companyFinancialsLoading) return <Loading />

  return (
    <div className="position-relative">
      <div className="card card-flush mb-4">
        <div className="card-body py-5 px-6">
          <div className="row">
            <div className="col-md-6">
              <h3 className="fw-bolder text-dark fs-8 m-0">Investment Ask</h3>
              <p className="fs-7 text-justify">
                {currencies[companyFinancial?.currency ?? 0]} {companyFinancial?.ask.toLocaleString()}
              </p>
            </div>
            <div className="col-md-6">
              <h3 className="fw-bolder text-dark fs-8 m-0">Annual Revenue</h3>
              <p
                className="fs-7 text-justify">{currencies[companyDetails?.annualRevenueCurrency ?? 0]} {companyDetails?.annualRevenue?.toLocaleString() || '0'}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <span className="fw-bolder text-dark fs-8 m-0">Net Profit</span>
              <p
                className="fs-7 text-justify">{currencies[companyDetails?.netProfitCurrency ?? 0]} {companyDetails?.netProfit?.toLocaleString() || '0'}</p>
            </div>
            <div className="col-md-6">
              <span className="fw-bolder text-dark fs-8 m-0">Net Profit Margin</span>
              <p className="fs-7 text-justify">{netProfitMargin.toFixed(1) || '0'} %</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <span className="fw-bolder text-dark fs-8 m-0">Asset Value</span>
              <p
                className="fs-7 text-justify">{currencies[companyDetails?.assetValueCurrency ?? 0]} {companyDetails?.assetValue?.toLocaleString() || '0'}</p>
            </div>
            <div className="col-md-6">
              <span className="fw-bolder text-dark fs-8 m-0">Suitability</span>
              <p className="fs-7 text-justify">{suitability[companyDetails?.suitability ?? 3]}</p>
            </div>
          </div>
        </div>
      </div>
      <EditButton handleClose={handleClose} />
      <EditCompanyFinancials showModal={showModal} handleClose={handleClose} />
    </div>
  )
}

export {CompanyFinancials}
