import React, {FC, useEffect, useRef, useState} from 'react'
import {Formik, Form, Field} from 'formik'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../_theme/helpers/components/KTSVG'
import 'flatpickr/dist/flatpickr.min.css'
import * as Yup from 'yup'
import {useParams} from 'react-router-dom'
import {RootState} from '../../../../data/redux/Store'
import {useSelector} from 'react-redux'
import {countries} from '../../../../utils/countries'
import {
  createFinancierSelectors,
  useGetFinancierCountriesQuery,
  useGetFinanciersQuery,
  useGetFinancierSectorsQuery,
  useUpdateFinancierMutation,
  useAddNewFinancierSectorsMutation,
  useDeleteFinancierSectorsMutation,
} from '../FinanciersApiSlice'
import {ratings} from '../constants/Ratings'
import {MultiSelect} from '../../shared/components/MultiSelect'
import {INameIdPair} from '../../shared/interfaces'
import {useGetSectorsQuery} from '../../companies/CompaniesApiSlice'

interface Props {
  showModal: boolean
  handleClose: () => void
  currentPage: number
  pageSize: number
}

export const UpdateFinancierGeneralInformation: FC<Props> = ({
                                                               showModal,
                                                               handleClose,
                                                               currentPage,
                                                               pageSize,
                                                             }) => {
  const FinancierGeneralInformationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').label('Name'),
    slogan: Yup.string().nullable().label('Slogan'),
    about: Yup.string().nullable().label('About'),
    financierCategory: Yup.string().nullable().label('Financier Category'),
    country: Yup.string().nullable().label('Country'),
    region: Yup.string().nullable().label('Region'),
    address: Yup.string().nullable().label('Address'),
  })

  const [updateFinancier, {isLoading}] = useUpdateFinancierMutation()
  const [deleteFinancierSectors] = useDeleteFinancierSectorsMutation()
  const [addFinancierSectors] = useAddNewFinancierSectorsMutation()
  const {id} = useParams<{id: string}>() as {id: string}
  useGetFinanciersQuery({page: currentPage, pageSize: pageSize})
  const {data: financierCountriesState} = useGetFinancierCountriesQuery()
  const financierCountries = Object.values(
    financierCountriesState?.entities?.entities ?? {},
  ).filter(Boolean) as INameIdPair[]

  const {selectById: selectFinancierById} = createFinancierSelectors({
    page: currentPage,
    pageSize,
  })
  const financierDetails = useSelector((state: RootState) => selectFinancierById(state, id))

  const {data: sectorsState} = useGetSectorsQuery()

  const sectorsList = Object.values(sectorsState?.entities || {}).filter(Boolean) as INameIdPair[]
  const {data: financierSectorsState} = useGetFinancierSectorsQuery(id)

  const financierSectorsIdsList = (financierSectorsState?.ids || []) as string[]

  const [selectedFinancierCountries, setSelectedFinancierCountries] = useState<INameIdPair[]>([])
  const [selectedFinancierSectors, setSelectedFinancierSectors] = useState<INameIdPair[]>([])

  const initialLoad = useRef(true)

  useEffect(() => {
    if (showModal) {
      initialLoad.current = true
    }
  }, [showModal])

  useEffect(() => {
    if (financierDetails && initialLoad.current) {
      const defaultSelectedFinancierCountries = financierCountries.filter((country) =>
        financierDetails?.countries.includes(country.id),
      )
      const defaultSelectedFinancierSectors = sectorsList.filter((sector) =>
        financierSectorsIdsList.includes(sector.id),
      )
      setSelectedFinancierCountries(defaultSelectedFinancierCountries)
      setSelectedFinancierSectors(defaultSelectedFinancierSectors)
      initialLoad.current = false
    }
  }, [financierDetails, financierCountries, sectorsList])

  const initialValues = {
    name: financierDetails?.name ?? '',
    slogan: financierDetails?.slogan ?? '',
    about: financierDetails?.about ?? '',
    financierCategory: financierDetails?.classificationRating ?? '',
    country: financierDetails?.country ?? '',
    region: financierDetails?.region ?? '',
    address: financierDetails?.address ?? '',
  }

  const handleSave = async (values: any) => {
    const updatedFinancierData = {
      id: financierDetails?.id,
      name: values.name,
      slogan: values.slogan,
      about: values.about,
      classification_rating: values.financierCategory,
      country: values.country,
      region: values.region,
      address: values.address,
      countries: selectedFinancierCountries?.map((country) => country.id) ?? [],
    }

    // Extract IDs from selectedFinancierSectors
    const selectedSectorIds: string[] = selectedFinancierSectors.map(sector => sector.id)

    // Identify sectors to delete
    const sectorsToDelete: string[] = financierSectorsIdsList.filter(id => !selectedSectorIds.includes(id)) as string[]

    // Identify sectors to add
    const sectorsToAdd = selectedSectorIds.filter(id => !financierSectorsIdsList.includes(id))
    const financierSectorObjects = sectorsToAdd.map((sectorId) => ({
      financier_id: id,
      financing_sector_id: sectorId,
    }))

    try {
      await Promise.all([
        deleteFinancierSectors(sectorsToDelete).unwrap(),
        addFinancierSectors(financierSectorObjects).unwrap(),
        updateFinancier(updatedFinancierData).unwrap(),
      ])
      setSelectedFinancierCountries([])
      setSelectedFinancierSectors([])
      handleClose()
    } catch (error) {
      setSelectedFinancierCountries([])
      setSelectedFinancierSectors([])
      handleClose()
    }
  }

  const handleCloseModal = () => {
    setSelectedFinancierCountries([])
    setSelectedFinancierSectors([])
    handleClose()
  }

  return (
    <>
      <Modal
        id="kt_edit_business_operation"
        aria-hidden={true}
        dialogClassName="h-auto"
        show={showModal}
        onHide={handleCloseModal}
      >
        <div className="modal-content w-700px" role="document">
          <Formik
            initialValues={initialValues || {}}
            validationSchema={FinancierGeneralInformationSchema}
            onSubmit={handleSave}
          >
            {({values, errors, touched, isValid, setFieldValue, setFieldTouched}) => (
              <Form>
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title ps-1">Edit Financier General Information</h5>
                    <div
                      className="btn btn-sm btn-icon btn-active-color-danger"
                      data-bs-dismiss="modal"
                      onClick={handleCloseModal}
                    >
                      <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
                    </div>
                  </div>
                  <div className="modal-body px-9">
                    <div className="row fv mb-4">
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="name"
                          className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark required"
                        >
                          Name
                        </label>
                        <Field
                          type="text"
                          name="name"
                          className="form-control form-control-sm"
                          style={{color: '#616162', fontWeight: 300}}
                        />
                        {touched.name && <div className="text-danger mt-2">{errors.name}</div>}
                      </div>
                    </div>
                    <div className="row fv mb-4">
                      <div className="col-md-12 mb-3">
                        <label
                          htmlFor="about"
                          className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark"
                        >
                          About
                        </label>
                        <Field
                          as="textarea"
                          name="about"
                          className="form-control form-control-sm text-secondary-20"
                          style={{color: '#616162', fontWeight: 300}}
                        />
                        {touched.about && <div className="text-danger mt-2">{errors.about}</div>}
                      </div>
                    </div>
                    <div className="row fv mb-4">
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="slogan"
                          className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark"
                        >
                          Slogan
                        </label>
                        <Field
                          type="text"
                          name="slogan"
                          className="form-control form-control-sm"
                          style={{color: '#616162', fontWeight: 300}}
                        />
                        {touched.slogan && <div className="text-danger mt-2">{errors.slogan}</div>}
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="financierCategory"
                          className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark"
                        >
                          Financier Category
                        </label>
                        <Field
                          as="select"
                          name="financierCategory"
                          className="form-select form-select-sm"
                          style={{color: '#616162', fontWeight: 300}}
                        >
                          <option value="">Select a category</option>
                          {ratings.map((option) => (
                            <option key={option?.value} value={option?.value}>
                              {option?.label}
                            </option>
                          ))}
                        </Field>
                        {touched.financierCategory && (
                          <div className="text-danger mt-2">{errors.financierCategory}</div>
                        )}
                      </div>
                    </div>
                    <div className="row fv mb-4">
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="country"
                          className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark"
                        >
                          Country
                        </label>
                        <Field
                          as="select"
                          name="country"
                          className="form-select form-select-sm"
                          style={{color: '#616162', fontWeight: 300}}
                        >
                          <option value="">Select Country</option>
                          {countries.map((option) => (
                            <option key={option.value} value={option.label}>
                              {option.label}
                            </option>
                          ))}
                        </Field>
                        {touched.country && (
                          <div className="text-danger mt-2">{errors.country}</div>
                        )}
                      </div>

                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="region"
                          className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark"
                        >
                          Region
                        </label>
                        <Field
                          type="text"
                          name="region"
                          className="form-control form-control-sm"
                          style={{color: '#616162', fontWeight: 300}}
                        />
                      </div>
                    </div>
                    <div className="row fv mb-4">
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="address"
                          className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark"
                        >
                          Address
                        </label>
                        <Field
                          type="text"
                          name="address"
                          className="form-control form-control-sm"
                          style={{color: '#616162', fontWeight: 300}}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="sectorsOfInterest"
                          className="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          Sectors of Interest
                        </label>
                        <MultiSelect
                          selectedValues={selectedFinancierSectors}
                          options={sectorsList}
                          setOption={setSelectedFinancierSectors}
                        />
                      </div>
                    </div>
                    {/* <div className='row fv mb-4'>
                      <div className='col-md-6 mb-3'>
                        <label
                          htmlFor='countries'
                          className='d-flex align-items-center fs-5 fw-bold mb-2 text-dark'
                        >
                          Countries of Operation
                        </label>
                        <MultiSelect
                          selectedValues={selectedFinancierCountries}
                          options={financierCountries}
                          setOption={setSelectedFinancierCountries}
                        />
                      </div>
                    </div> */}
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary text-dark"
                      onClick={handleCloseModal}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="btn btn-danger"
                      onClick={() => {
                        handleSave(values)
                      }}
                      disabled={!isValid || isLoading}
                    >
                      <span className="indicator-label">
                        {!isLoading && 'Save'}
                        {isLoading && (
                          <>
                            <span className="spinner-border h-15px w-15px align-middle text-white-400"></span>
                          </>
                        )}
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  )
}
