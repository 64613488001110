import React, {ComponentType, useState} from 'react'
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import {ICompanyContact} from '../interfaces'
import {countries} from '../../../../utils/countries'
import Modal from 'react-bootstrap/Modal'
import {useParams} from 'react-router-dom'
import {
  useGetCompanyDetailsQuery,
  useGetCompanyContactsQuery,
  useUpdateCompanyMutation,
  useUpdateCompanyContactMutation,
} from '../CompaniesApiSlice'
import {KTSVG} from '../../../../_theme/helpers'
import {countryCodes} from '../../../../utils/countryCodes'
import {CountryCustomSelect} from '../../shared/components/CountryCustomSelect'

type Props = {
  showModal: boolean
  handleClose: () => void
}

export const EditCompanyContactInformation: React.FC<Props> = ({showModal, handleClose}) => {

  const ContactInformationValidationSchema = Yup.object().shape({
    country: Yup.string().required('Country is required'),
    region: Yup.string().nullable(),
    district: Yup.string().nullable(),
    address: Yup.string().nullable(),
    facebook: Yup.string().nullable(),
    linkedIn: Yup.string().nullable(),
    twitter: Yup.string().nullable(),
    website: Yup.string().nullable(),
    companyContactPersonName: Yup.string().required('Name of Contact Person is required'),
    companyContactPersonEmail: Yup.string().email().nullable(),
    companyContactPersonPhone: Yup.string()
      .matches(/^[0-9]{9}$/, 'Phone number must be 9 digits')
      .nullable()
      .label('Contact Person Telephone'),
    companyContactPersonAddress: Yup.string().nullable(),
  })

  const [updateCompany, {isLoading: isUpdateCompanyLoading}] = useUpdateCompanyMutation()
  const [updateCompanyContact, {isLoading: isUpdateCompanyContactLoading}] =
    useUpdateCompanyContactMutation()
  const {id} = useParams<{id: string}>() as {id: string}
  const {data: companyState} = useGetCompanyDetailsQuery(id)
  const {data: companyContactsState} = useGetCompanyContactsQuery(id)

  const companyDetails = companyState?.entities[id]

  let companyContact: ICompanyContact | null | undefined = null
  if (companyContactsState?.entities) {
    const contactsArray = Object.values(companyContactsState?.entities)
    companyContact = contactsArray.length > 0 ? contactsArray[0] : null
  }
  const countryPrefix = companyContact?.companyContactPersonTelephone?.split('-')[0] || '+256'
  const phoneNumber = companyContact?.companyContactPersonTelephone?.split('-')[1] || ''
  const [countryCode, setCountryCode] = useState<string>(countryPrefix)
  const contactInformationForm = {
    country: companyDetails?.country || '',
    region: companyDetails?.region || '',
    district: companyDetails?.district || '',
    address: companyDetails?.address || '',
    facebook: companyDetails?.facebook || '',
    linkedIn: companyDetails?.linkedIn || '',
    twitter: companyDetails?.twitter || '',
    website: companyDetails?.website || '',
    companyContactPersonName: companyContact?.companyContactPersonName || '',
    companyContactPersonEmail: companyContact?.companyContactPersonEmail || '',
    companyContactPersonPhone: phoneNumber,
    companyContactPersonAddress: companyContact?.companyContactPersonAddress || '',
  }

  const handleSave = async (values: any) => {
    const updatedCompanyDetails = {
      ...companyDetails,
      country: values.country,
      region: values.region,
      district: values.district,
      address: values.address,
      website: values.website,
      facebook: values.facebook,
      linkedIn: values.linkedIn,
      twitter: values.twitter,
      sector: values.sectors,
    }
    const updatedContactPersonDetails = {
      id: companyContact?.id,
      name: values.companyContactPersonName,
      email: values.companyContactPersonEmail,
      telephone: values.companyContactPersonPhone
        ? `${countryCode}-${values.companyContactPersonPhone}`
        : '',
      address: values.companyContactPersonAddress,
      companyId: companyDetails?.id,
    }
    try {
      await Promise.all([
        updateCompany(updatedCompanyDetails).unwrap(),
        updateCompanyContact(updatedContactPersonDetails).unwrap(),
      ])
      handleClose()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Modal
        id="kt_edit_contact_information"
        aria-hidden="true"
        dialogClassName="h-auto modal-dialog-centered"
        show={showModal}
        onHide={handleClose}
        as={React.Fragment}
      >
        <div className="modal-content w-700px" role="document">
          <Formik
            initialValues={contactInformationForm}
            validationSchema={ContactInformationValidationSchema}
            onSubmit={handleSave}
          >
            {({errors, touched, values, isValid}) => (
              <Form>
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title ps-1" id="editFinancialsModalLabel">
                      Edit Contact Information
                    </h5>
                    <div
                      className="btn btn-sm btn-icon btn-active-color-danger"
                      onClick={handleClose}
                    >
                      <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
                    </div>
                  </div>
                  <div className="modal-body px-9">
                    <div className="row fv mb-4">
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="country"
                          className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark required"
                        >
                          Country
                        </label>
                        <Field
                          as="select"
                          name="country"
                          className="form-select form-select-sm select2-hidden-accessible"
                          style={{color: '#616162', fontWeight: 300}}
                        >
                          <option value="" disabled>
                            Select Country
                          </option>
                          {countries.map((option) => (
                            <option key={option.value} value={option.label}>
                              {option.label}
                            </option>
                          ))}
                        </Field>
                        {touched.country && (
                          <div className="text-danger mt-2">{errors.country}</div>
                        )}
                      </div>

                      <div className="col-md-6 mb-3">
                        <label htmlFor="region" className="form-label text-dark">
                          Region
                        </label>
                        <Field type="text" name="region" className="form-control form-control-sm"
                               style={{color: '#616162', fontWeight: 300}} />
                        {touched.region && <div className="text-danger mt-2">{errors.region}</div>}
                      </div>
                    </div>
                    <div className="row fv mb-4">
                      <div className="col-md-6 mb-3">
                        <label htmlFor="district" className="form-label text-dark">
                          District
                        </label>
                        <Field
                          type="text"
                          name="district"
                          className="form-control form-control-sm"
                          style={{color: '#616162', fontWeight: 300}}
                        />
                        {touched.district && (
                          <div className="text-danger mt-2">{errors.district}</div>
                        )}
                      </div>

                      <div className="col-md-6 mb-3">
                        <label htmlFor="address" className="form-label text-dark">
                          Address
                        </label>
                        <Field
                          type="text"
                          name="address"
                          className="form-control form-control-sm"
                          style={{color: '#616162', fontWeight: 300}}
                        />
                        {touched.address && (
                          <div className="text-danger mt-2">{errors.address}</div>
                        )}
                      </div>
                    </div>

                    {/* Socials */}
                    <div className="row fv mb-4">
                      <div className="col-md-6 mb-3">
                        <label htmlFor="facebook" className="form-label text-dark">
                          Facebook
                        </label>
                        <Field
                          type="text"
                          name="facebook"
                          className="form-control form-control-sm"
                          style={{color: '#616162', fontWeight: 300}}
                        />
                        {touched.facebook && (
                          <div className="text-danger mt-2">{errors.facebook}</div>
                        )}
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="linked" className="form-label text-dark">
                          LinkedIn
                        </label>
                        <Field
                          type="text"
                          name="linkedIn"
                          className="form-control form-control-sm"
                          style={{color: '#616162', fontWeight: 300}}
                        />
                        {touched.linkedIn && (
                          <div className="text-danger mt-2">{errors.linkedIn}</div>
                        )}
                      </div>
                    </div>

                    <div className="row fv mb-4">
                      <div className="col-md-6 mb-3">
                        <label htmlFor="twitter" className="form-label text-dark">
                          Twitter
                        </label>
                        <Field
                          type="text"
                          name="twitter"
                          className="form-control form-control-sm"
                          style={{color: '#616162', fontWeight: 300}}
                        />
                        {touched.twitter && (
                          <div className="text-danger mt-2">{errors.twitter}</div>
                        )}
                      </div>

                      <div className="col-md-6 mb-3">
                        <label htmlFor="website" className="form-label text-dark">
                          Website URL
                        </label>
                        <Field
                          type="text"
                          name="website"
                          className="form-control form-control-sm"
                          style={{color: '#616162', fontWeight: 300}}
                        />
                        {touched.website && (
                          <div className="text-danger mt-2">{errors.website}</div>
                        )}
                      </div>
                    </div>

                    {/* Contact Person Details */}

                    <div className="row fv mb-4">
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="companyContactPersonName"
                          className="form-label text-dark required"
                        >
                          Name of Contact Person
                        </label>
                        <Field
                          type="text"
                          name="companyContactPersonName"
                          className="form-control form-control-sm"
                          style={{color: '#616162', fontWeight: 300}}
                        />
                        {touched.companyContactPersonName && (
                          <div className="text-danger mt-2">{errors.companyContactPersonName}</div>
                        )}
                      </div>

                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="companyContactPersonEmail"
                          className="form-label text-dark"
                        >
                          Email of Contact person
                        </label>
                        <Field
                          type="text"
                          name="companyContactPersonEmail"
                          className="form-control form-control-sm"
                          style={{color: '#616162', fontWeight: 300}}
                        />
                        {touched.companyContactPersonEmail && (
                          <div className="text-danger mt-2">{errors.companyContactPersonEmail}</div>
                        )}
                      </div>
                    </div>

                    <div className="row fv mb-4">
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="companyContactPersonPhone"
                          className="form-label text-dark"
                        >
                          Phone Number of Contact person
                        </label>
                        <div className="input-group input-group-sm mb-3">
                          <CountryCustomSelect name="dialCode" options={countryCodes} setCountryCode={setCountryCode}
                                               countryCode={countryPrefix} />
                          <Field
                            type="number"
                            className="form-control form-control-sm border-start-0 rounded-start-0"
                            style={{color: '#616162', fontWeight: 300}}
                            name="companyContactPersonPhone"
                          />
                          {touched.companyContactPersonPhone && (
                            <div className="text-danger mt-2">
                              {errors.companyContactPersonPhone}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="companyContactPersonAddress"
                          className="form-label text-dark"
                        >
                          Address line of Contact person
                        </label>
                        <Field
                          type="text"
                          name="companyContactPersonAddress"
                          className="form-control form-control-sm"
                          style={{color: '#616162', fontWeight: 300}}
                        />
                        {touched.companyContactPersonAddress && (
                          <div className="text-danger mt-2">
                            {errors.companyContactPersonAddress}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer ">
                    <button
                      type="button"
                      className="btn btn-secondary text-dark"
                      onClick={handleClose}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="btn btn-danger"
                      disabled={!isValid || isUpdateCompanyLoading || isUpdateCompanyContactLoading}
                    >
                      <span className="indicator-label">
                        {!isUpdateCompanyLoading && !isUpdateCompanyContactLoading && 'Save'}
                        {(isUpdateCompanyLoading || isUpdateCompanyContactLoading) && (
                          <>
                            <span className="spinner-border h-15px w-15px align-middle text-white-400"></span>
                          </>
                        )}
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  )
}
