import {useState, FC} from 'react'
import Search from '../../shared/components/Search'
import {
  useAddNewDocumentFolderMutation,
  useDeleteDocumentFolderMutation,
  useGetDocumentFoldersQuery,
  useUpdateDocumentFolderMutation,
} from '../CompaniesApiSlice'
import {ICompanyDocumentFolders} from '../interfaces'
import {useParams} from 'react-router-dom'
import {formatBytes} from '../../../../utils/numberHelpers'
import Loading from '../../shared/components/Loading'
import {OptionsPopup} from '../../shared/components/OptionsPopup'
import {CreateCompanyDocumentsFolder} from '../modals/CreateCompanyDocumentsFolder'
import {UpdateFolder} from '../../shared/components/UpdateFolder'
import {DeleteModal} from '../../shared/components/DeleteModal'

type CompanyFolderDetailsCardProps = {
  handleFolderToggle: () => void
  setSelectedFolder: (folder: {
    index: number
    id: string
    folderName: string
    parentFolderId?: string | null
  }) => void
  selectedFolder: {index: number; id: string; folderName: string}
  showModal: boolean
  handleClose: () => void
}
export const CompanyFoldersCard: FC<CompanyFolderDetailsCardProps> = ({
                                                                        handleFolderToggle,
                                                                        setSelectedFolder,
                                                                        showModal,
                                                                        handleClose,
                                                                        selectedFolder,
                                                                      }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [showDeleteFolderModal, setShowDeleteFolderModal] = useState(false)
  const [showEditFolderModal, setShowEditFolderModal] = useState<boolean>(false)
  const {id} = useParams<{id: string}>() as {id: string}
  const [deleteFolder, {isLoading: deletingFolder}] = useDeleteDocumentFolderMutation()
  const [updateFolder, {isLoading: updatingFolder}] = useUpdateDocumentFolderMutation()
  const [addCompanyFolder, {isLoading: creatingCompanyFolder}] = useAddNewDocumentFolderMutation()
  const {data: companyDocumentFoldersState, isFetching: isGettingFolders} =
    useGetDocumentFoldersQuery({
      companyId: id,
      searchTerm,
      includeOnlyParentFolders: true,
    })
  const companyDocumentFolders: ICompanyDocumentFolders[] = Object.values(
    companyDocumentFoldersState?.entities || {},
  ) as ICompanyDocumentFolders[]

  const handleFolderClick = (id: string, folderName: string) => {
    setSelectedFolder({index: 1, id, folderName})
  }
  const handleFolderOptionsClick = (id: string, folderName: string) => {
    if (selectedFolder.folderName === '') {
      setSelectedFolder({index: 0, id, folderName})
    }
  }
  const handleDeleteModal = () => {
    setShowDeleteFolderModal(!showDeleteFolderModal)
  }
  const handleEditModal = () => {
    setShowEditFolderModal(!showEditFolderModal)
  }

  const handleDelete = async () => {
    try {
      await deleteFolder(selectedFolder.id).unwrap()
      setSelectedFolder({index: 0, id: '', folderName: '', parentFolderId: null})
      handleDeleteModal()
    } catch (error) {
    }
  }

  const handleUpdateFolder = async (name: string) => {
    try {
      await updateFolder({
        folderName: name,
        companyId: id,
        id: selectedFolder.id,
      }).unwrap()
      setSelectedFolder({index: 0, id: '', folderName: '', parentFolderId: null})
      handleEditModal()
    } catch (error) {
    }
  }
  const handleCreateFolder = async (name: string) => {
    try {
      await addCompanyFolder({
        folderName: name,
        companyId: id,
      }).unwrap()
      setSelectedFolder({index: 0, id: '', folderName: ''})
      handleClose()
    } catch (error) {
    }
  }

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between position-relative py-2 px-6">
        <div className="">
          <h6>Company Documents</h6>
        </div>
        <div className="d-flex align-items-center gap-3">
          <Search setSearchTerm={setSearchTerm} />
          <button
            type="button"
            className="btn btn-danger d-flex align-items-center justify-content-center btn-sm "
            onClick={handleClose}
          >
            <i className="fas fa-plus fs-5 me-1"></i>
            <span className="ms-1">New Folder</span>
          </button>
        </div>
      </div>
      <div className="row">
        {isGettingFolders ? (
          <div className="d-flex justify-content-center">
            <Loading />
          </div>
        ) : (
          companyDocumentFolders.map((folder, index) => (
            <div key={index} className="col-md-6 col-lg-6 col-sm-12 mt-5 min-height-200px">
              <div
                className="card border border-gray-300 mb-5 px-5 cursor-pointer h-100 d-flex flex-column justify-content-between">
                <div className="d-flex border-0 pt-5 justify-content-between align-items-center">
                  <i className={`fa fa-folder text-warning fs-2x me-2`}></i>
                  <div
                    className="flex-grow-1 d-flex justify-content-end p-2 pb-0 cursor-pointer"
                    onClick={() => handleFolderOptionsClick(folder.id, folder.folderName)}
                  >
                    <OptionsPopup
                      selectedEntity={selectedFolder}
                      setSelectedEntity={setSelectedFolder}
                      handleDelete={handleDelete}
                      handleUpdateEntity={handleUpdateFolder}
                      updatingEntity={updatingFolder}
                      showDeleteModal={showDeleteFolderModal}
                      showEditModal={showEditFolderModal}
                      deletingEntity={deletingFolder}
                      handleDeleteModal={handleDeleteModal}
                      handleEditModal={handleEditModal}
                      entity="Folder"
                      includeFilePreviewOption={false}
                      includeUpdateOption={true}
                      includeDeleteOption={true}
                      isEllipsis={true}
                    />
                  </div>
                </div>
                <div
                  onClick={() => handleFolderClick(folder.id, folder.folderName)}
                  className="flex-grow-1 d-flex justify-content-start align-items-center"
                >
                  <span className="fw-bolder text-dark fs-4 lh-2 w-10">{folder.folderName}</span>
                </div>
                <div
                  className={`mb-5 d-flex justify-content-between pt-6`}
                  onClick={() => handleFolderClick(folder.id, folder.folderName)}
                >
                  <span className="fw-bold text-dark fs-5">{`${folder.documentCount} Files`}</span>
                  <span className="fw-bolder text-dark fs-5">
                    {formatBytes(folder.totalDocumentSize)}
                  </span>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <CreateCompanyDocumentsFolder
        showCreateFolderModal={showModal}
        handleCreateFolderModal={handleClose}
      />
      <UpdateFolder
        showEditModal={showEditFolderModal}
        selectedFolder={selectedFolder}
        setSelectedFolder={setSelectedFolder}
        handleEditModal={handleEditModal}
        isLoading={updatingFolder}
        handleUpdateFolder={handleUpdateFolder}
      />
      <DeleteModal
        showDeleteModal={showDeleteFolderModal}
        isLoading={deletingFolder}
        handleDelete={handleDelete}
        item={'File'}
        handleClose={handleDeleteModal}
      />
    </div>
  )
}
