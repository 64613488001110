import {useState, FC} from 'react'
import Search from '../../shared/components/Search'
import {
  useAddNewServiceProviderDocumentFolderMutation,
  useDeleteDocumentFolderMutation,
  useGetServiceProviderDocumentFoldersQuery,
  useUpdateServiceProviderDocumentFolderMutation,
} from '../ServiceProvidersApiSlice'
import {useParams} from 'react-router-dom'
import {IProviderDocumentFolders} from '../interfaces'
import {CreateProviderDocumentsFolder} from '../modals/CreateProviderDocumentsFolder'
import {formatBytes} from '../../../../utils/numberHelpers'
import Loading from '../../shared/components/Loading'
import {OptionsPopup} from '../../shared/components/OptionsPopup'
import {UpdateFolder} from '../../shared/components/UpdateFolder'
import {DeleteModal} from '../../shared/components/DeleteModal'
import {CreateFolder} from '../../shared/modals/CreateFolder'

type ServiceProviderDocumentFolderCardProps = {
  handleFolderToggle: () => void
  setSelectedFolder: (folder: {
    index: number
    id: string
    folderName: string
    parentFolderId?: string | null
  }) => void
  selectedFolder: {index: number; id: string; folderName: string}
  showModal: boolean
  handleClose: () => void
}
export const ServiceProviderDocumentFolderCard: FC<ServiceProviderDocumentFolderCardProps> = ({
  handleFolderToggle,
  setSelectedFolder,
  selectedFolder,
  showModal,
  handleClose,
}) => {
  const {id} = useParams<{id: string}>() as {id: string}
  const [searchTerm, setSearchTerm] = useState('')
  const [showDeleteFolderModal, setShowDeleteFolderModal] = useState(false)
  const [showEditFolderModal, setShowEditFolderModal] = useState<boolean>(false)
  const [deleteFolder, {isLoading: deletingFolder}] = useDeleteDocumentFolderMutation()
  const [addProviderFolder, {isLoading: creatingProviderFolder}] =
    useAddNewServiceProviderDocumentFolderMutation()
  const [updateFolder, {isLoading: updatingFolder}] =
    useUpdateServiceProviderDocumentFolderMutation()
  const {data: providerDocumentFoldersState, isLoading} = useGetServiceProviderDocumentFoldersQuery(
    {
      providerId: id,
      searchTerm,
      includeOnlyParentFolders: true,
    }
  )
  const providerDocumentFolders: IProviderDocumentFolders[] = Object.values(
    providerDocumentFoldersState?.entities || {}
  ) as IProviderDocumentFolders[]

  const handleFolderClick = (id: string, folderName: string) => {
    setSelectedFolder({index: 1, id, folderName})
  }

  const handleFolderOptionsClick = (id: string, folderName: string) => {
    if (selectedFolder.folderName === '') {
      setSelectedFolder({index: 0, id, folderName})
    }
  }
  const handleDeleteModal = () => {
    setShowDeleteFolderModal(!showDeleteFolderModal)
  }
  const handleEditModal = () => {
    setShowEditFolderModal(!showEditFolderModal)
  }

  const handleDelete = async () => {
    try {
      await deleteFolder(selectedFolder.id).unwrap()
      setSelectedFolder({index: 0, id: '', folderName: '', parentFolderId: null})
      handleDeleteModal()
    } catch (error) {}
  }

  const handleUpdateFolder = async (name: string) => {
    try {
      await updateFolder({
        folderName: name,
        providerId: id,
        id: selectedFolder.id,
      }).unwrap()
      setSelectedFolder({index: 0, id: '', folderName: '', parentFolderId: null})
      handleEditModal()
    } catch (error) {}
  }
  const handleCreateFolder = async (name: string) => {
    try {
      await addProviderFolder({
        folderName: name,
        providerId: id,
      }).unwrap()
      setSelectedFolder({index: 0, id: '', folderName: ''})
      handleClose()
    } catch (error) {}
  }

  return (
    <>
      <div className='d-flex align-items-center justify-content-between position-relative py-3'>
        <div className='ps-3'>
          <h6>Service Provider Documents</h6>
        </div>
        <div className='d-flex align-items-center gap-3 pe-3'>
          <Search setSearchTerm={setSearchTerm} />
          <button
            type='button'
            className='btn btn-danger d-flex align-items-center justify-content-center btn-sm'
            onClick={handleClose}
          >
            <i className='fas fa-plus fs-5 me-1'></i>
            <span className='ms-1'>New Folder</span>
          </button>
        </div>
      </div>
      <div className='row mx-0'>
        {isLoading ? (
          <div className='d-flex justify-content-center'>
            <Loading />
          </div>
        ) : (
          providerDocumentFolders.map((folder, index) => (
            <div key={index} className='col-md-6 col-lg-6 col-sm-12 p-1'>
              <div className='card border border-gray-300 mb-0 px-4 py-3'>
                <div className='d-flex border-0 pt-5 justify-content-between align-items-center'>
                  <i className={`fa fa-folder text-warning fs-2x me-2`}></i>

                  <div
                    className='flex-grow-1 d-flex justify-content-end p-2 cursor-pointer'
                    onClick={() => handleFolderOptionsClick(folder.id, folder.folderName)}
                  >
                    <OptionsPopup
                      selectedEntity={selectedFolder}
                      setSelectedEntity={setSelectedFolder}
                      handleDeleteModal={handleDeleteModal}
                      handleEditModal={handleEditModal}
                      includeFilePreviewOption={false}
                      includeUpdateOption={true}
                      includeDeleteOption={true}
                      isEllipsis={true}
                    />
                  </div>
                </div>
                <div
                  onClick={() => handleFolderClick(folder.id, folder.folderName)}
                  className='flex-grow-1 d-flex justify-content-start align-items-center pt-4'
                >
                  <span className='fw-bolder text-dark fs-4 lh-1'>{folder.folderName}</span>
                </div>
                <div
                  className={'mb-5 d-flex justify-content-between pt-6'}
                  onClick={() => handleFolderClick(folder.id, folder.folderName)}
                >
                  <span className='fw-bold text-dark fs-5'>{`${folder.fileCount} Files`}</span>
                  <span className='fw-bolder text-dark fs-5'>
                    {formatBytes(folder.totalFileSize)}
                  </span>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <CreateFolder
        showCreateFolderModal={showModal}
        handleCreateFolderModal={handleClose}
        handleCreateFolder={handleCreateFolder}
        isLoading={creatingProviderFolder}
      />
      <UpdateFolder
        showEditModal={showEditFolderModal}
        selectedFolder={selectedFolder}
        setSelectedFolder={setSelectedFolder}
        handleEditModal={handleEditModal}
        isLoading={updatingFolder}
        handleUpdateFolder={handleUpdateFolder}
      />
      <DeleteModal
        showDeleteModal={showDeleteFolderModal}
        isLoading={deletingFolder}
        handleDelete={handleDelete}
        item={'File'}
        handleClose={handleDeleteModal}
      />
    </>
  )
}
